<template>
  <div class="d-flex h-full flex-column">
    <v-text-field
      v-model="search"
      class="mx-4"
      :prepend-icon="searchIcon"
      :placeholder="`Search within ${$options.filters.lower(field.name)}`"
      :label="`Search within ${$options.filters.lower(field.name)}`"
      flat
      solo
      hide-details
    />
    <v-divider />
    <FieldHeader
      :field="field"
      @save="applyFilters"
      @back="$emit('back')"
    />
    <v-divider />
    <v-virtual-scroll
      bench="20"
      :items="itemsToShow"
      height="100%"
      item-height="40"
    >
      <template #default="{ item }">
        <v-list-item :key="item.id">
          <v-list-item-action class="mr-2">
            <v-checkbox
              v-model="selectedItems"
              dense
              :value="item.id"
              color="black"
            />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              class="hover-pointer subtitle-2"
              :title="item.name"
              @click="toggleItem(item)"
            >
              {{ item.name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-virtual-scroll>
  </div>
</template>

<script>
import { mdiMagnify } from '@mdi/js';
import { mapGetters } from 'vuex';
import { orderBy } from 'lodash';
import FieldHeader from '@/components/print/checkout/snailblast/listpurchase/filters/field/Header';
export default {
  name: 'SnailblastListPurchaseFiltersSelect',
  components: { FieldHeader },
  props: { field: { type: Object, default: () => {} } },

  data() {
    return {
      searchIcon: mdiMagnify,
      search: '',
      loading: false,
      selectedItems: []
    };
  },
  computed: {
    itemsToShow() {
      if (!this.field) {
        return [];
      }

      let list = this.field.selectItems || [];

      if (this.search) {
        list = list.filter((x) => x.name.toLowerCase().includes(this.search.toLowerCase()));
      }

      list = list.map((x) =>
        Object.assign(x, { selected: (this.selectedItems || []).includes(x.id) })
      );

      return orderBy(list, ['selected'], ['desc']);
    },
    ...mapGetters({
      lookup_field: 'snailblast/lookup/list_purchase_field',
      filters: 'snailblast/campaign/list_purchase_filters'
    })
  },
  created() {
    if (!this.field.selectItems || !this.field.selectItems.length) {
      this.loading = true;
      this.$store
        .dispatch('snailblast/lookup/getListPurchaseFieldSelectItems', this.field.id)
        .catch((error) => {
          this.logError(error, 'Error getting select items');
        })
        .finally(() => {
          this.loading = false;
        });
    }
    this.selectedItems = this.filters
      .filter((x) => x.field.id === this.field.id)
      .map((x) => x.value);
  },
  methods: {
    applyFilters() {
      this.addFilters(
        this.selectedItems.map((x) => ({
          field: this.field,
          value: x,
          text: (this.lookup_field(this.field.id).selectItems || [])
            .filter((y) => y.id === x)
            .map((y) => y.name)[0]
        }))
      );
    },
    selectFilter(item) {
      this.addFilters([
        {
          field: this.field,
          value: item.id,
          text: item.name
        }
      ]);
    },
    addFilters(filters) {
      let newFilters = this.filters.filter((x) => x.field.id !== this.field.id);
      newFilters = newFilters.concat(filters);
      this.$emit('setfilters', newFilters);
    },
    toggleItem(item) {
      const index = this.selectedItems.findIndex((x) => x === item.id);
      if (index >= 0) {
        this.selectedItems.splice(index, 1);
      } else {
        this.selectedItems.push(item.id);
      }
    }
  }
};
</script>
