<template>
  <Drawer
    :id="drawerId"
    title="Print Preview"
    :iframed="iframed"
  >
    <template #cart>
      <v-icon @click="$refs.edit.getMaterialQuantities()">
        {{ refreshIcon }}
      </v-icon>
    </template>
    <v-sheet class="mt-5">
      <PrintEditItem
        ref="edit"
        preview
        :drawerId="drawerId"
      />
    </v-sheet>
  </Drawer>
</template>

<script>
import { DRAWERS } from '../../utils/constants';
import Drawer from '../../components/layout/Drawer';
import PrintEditItem from '@/components/print/checkout/edititem/Index';
import { mapGetters } from 'vuex';
import { mdiRefresh } from '@mdi/js';

export default {
  name: 'PrintPreviewDrawer',

  components: {
    Drawer,
    PrintEditItem
  },

  props: {
    iframed: Boolean
  },

  data() {
    return {
      drawerId: DRAWERS.PRINT_PREVIEW,
      refreshIcon: mdiRefresh
    };
  },

  computed: {
    ...mapGetters({
      initialData: 'drawers/data',
      drawerOpenedAt: 'drawers/lastOpenedAt'
    })
  },

  watch: {
    drawerOpenedAt(val, oldVal) {
      if (this.drawerId === this.$store.getters['drawers/topDrawer']?.id) {
        this.editItem(this.initialData(this.drawerId) || {});
      }
    }
  },

  methods: {
    editItem(item) {
      this.$store.dispatch('printcart/setEditItem', item).then(async () => {
        await this.$refs.edit.loadData();
      });
    }
  }
};
</script>
