<template>
  <Drawer
    :id="drawerId"
    title="Add your design"
    height="75%"
    class="rounded-t-xl"
    bottom
    padded
  >
    <v-sheet>
      <v-row>
        <v-col>
          <v-btn
            block
            x-large
            elevation="0"
            outlined
            class="bg-light"
            @click="newUpload"
          >
            <v-icon class="mr-2">
              {{ uploadIcon }}
            </v-icon>
            Upload File
          </v-btn>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <div class="body-2 mt-2">
            {{ needDesignTerm }}
            <span
              class="hover-pointer primary--text"
              @click="newProject"
              >Design a new {{ categoryName }}</span
            >
          </div>
        </v-col>
      </v-row>
      <v-row
        dense
        class="my-3"
      >
        <v-col><v-divider /></v-col>
      </v-row>
      <v-row>
        <template v-if="projectsToDisplay && projectsToDisplay.length">
          <v-col
            v-for="project in projectsToDisplay"
            :key="project.id"
            cols="12"
          >
            <SelectProjectCard
              :name="project.name"
              :isUpload="project.isPrintUpload"
              :text1="project.coreProductName"
              :text2="$options.filters.shortDateWithTime(project.modifiedDate)"
              :imageUrl="project.previewUrl"
              class="hover-pointer"
              @click.native="selectProject(project)"
            />
          </v-col>
        </template>
      </v-row>
    </v-sheet>
  </Drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import { DRAWERS } from '../../../utils/constants';
import Drawer from '@/components/layout/Drawer';
import SelectProjectCard from './SelectProjectCard';
import { mdiCloudUpload, mdiAlertCircleOutline } from '@mdi/js';

export default {
  name: 'SelectProjectDrawer',

  components: {
    Drawer,
    SelectProjectCard
  },

  data() {
    return {
      drawerId: DRAWERS.PRINT_SELECT_PROJECT,
      alertIcon: mdiAlertCircleOutline,
      uploadIcon: mdiCloudUpload,
      categoryId: null
    };
  },

  computed: {
    projectsToDisplay() {
      const coreProductIds = this.core_products(this.categoryId).map((x) => x.id);
      var list = this.all_projects.filter((x) => coreProductIds.includes(x.coreProductId));
      return list;
    },

    categoryName() {
      return this.categories.find((x) => x.id === this.categoryId)?.name?.toLowerCase();
    },

    categoryNamePlural() {
      return this.categories.find((x) => x.id === this.categoryId)?.namePlural?.toLowerCase();
    },

    needDesignTerm() {
      if (this.projectsToDisplay && this.projectsToDisplay.length) return 'Need a design? ';
      return `You haven't created any ${this.categoryNamePlural} yet. `;
    },

    edit_item: {
      get() {
        return this.$store.getters['printcart/edit_item'];
      },
      set(val) {
        this.$store.dispatch('printcart/setEditItem', val);
      }
    },

    ...mapGetters({
      initialData: 'drawers/data',
      drawerOpenedAt: 'drawers/lastOpenedAt',
      categories: 'templates/printableCategories',
      all_projects: 'projects/all',
      core_products: 'templates/core_products_printable'
    })
  },

  watch: {
    drawerOpenedAt() {
      if (this.drawerId === this.$store.getters['drawers/topDrawer']?.id) {
        this.categoryId = this.edit_item.categoryId;
      }
    }
  },

  methods: {
    selectProject(project) {
      if (this.edit_item.categoryId !== this.categoryId) {
        this.edit_item.categoryId = this.categoryId;
      }
      if (this.edit_item.coreProductId !== project.coreProductId) {
        this.edit_item.coreProductId = project.coreProductId;
      }
      this.edit_item.project = project;
      this.$store.commit('drawers/CLOSE_DRAWER', this.drawerId);
    },

    newUpload() {
      this.$store.commit('drawers/SET_DATA', {
        drawerId: DRAWERS.EDITOR_DRAWER,
        data: { coreProductId: this.edit_item.coreProductId }
      });
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.EDITOR_DRAWER);
    },

    newProject() {
      var url = this.categories.find((x) => x.id === this.categoryId).url;
      this.$store.commit('drawers/SET_DATA', {
        drawerId: DRAWERS.CATALOG_DRAWER,
        data: { catalogUrl: url }
      });
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.CATALOG_DRAWER);
    }
  }
};
</script>

<style lang="scss" scoped>
.v-select ::v-deep input {
  width: 10px;
}
.v-select ::v-deep .v-select__selection {
  margin: 0 !important;
}
.v-select ::v-deep .v-input__control {
  min-height: 20px !important;
}
</style>
