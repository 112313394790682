<template>
  <div>
    <v-btn
      v-if="viewOnly"
      elevation="0"
      x-large
      block
      :loading="generatingUrl"
      class="rounded-md bordered border-light"
      @click="viewCert"
    >
      {{ buttonText }}
    </v-btn>
    <div
      v-else
      class="d-flex"
    >
      <v-btn
        elevation="0"
        class="rounded-md bordered border-light flex-grow-1 d-flex"
        x-large
        :loading="uploading"
        @click="handleFileInput"
      >
        <v-icon class="mr-2">
          {{ uploadIcon }}
        </v-icon>
        {{ buttonText }}
      </v-btn>
      <v-btn
        v-if="certificateId"
        elevation="0"
        x-large
        width="75"
        :loading="generatingUrl"
        class="rounded-md bordered border-light ml-3"
        @click="viewCert"
      >
        View
      </v-btn>
      <v-btn
        v-if="certificateId"
        elevation="0"
        x-large
        width="75"
        :loading="deleting"
        class="rounded-md bordered border-light ml-3"
        @click="deleteCert"
      >
        Delete
      </v-btn>
    </div>
    <input
      ref="fileInput"
      class="d-none"
      type="file"
      @change="upload"
    />
  </div>
</template>

<script>
import { mdiCloudUpload } from '@mdi/js';

export default {
  name: 'CertificateUploadButton',

  props: {
    buttonText: {
      type: String,
      default: 'Upload Certificate'
    },
    certificateId: {
      type: Number,
      default: 0
    },
    viewOnly: Boolean
  },

  data() {
    return {
      uploadIcon: mdiCloudUpload,
      uploading: false,
      generatingUrl: false,
      deleting: false
    };
  },

  computed: {},

  watch: {},

  mounted() {},

  methods: {
    handleFileInput() {
      this.$refs.fileInput.click();
    },

    upload() {
      this.uploading = true;
      var payload = {
        file: this.$refs.fileInput.files[0],
        certificateId: this.certificateId
      };
      this.$store.dispatch('user/addExemptionCertificateFile', payload).finally(() => {
        this.uploading = false;
        this.$root.$snackbarSuccess(
          'Your uploaded certificate has been received and is under review.'
        );
      });
    },

    viewCert() {
      this.generatingUrl = true;
      setTimeout(() => {
        this.$api
          .get(`user/getCertificateUrl?id=${this.certificateId}`)
          .then((response) => {
            const link = document.createElement('a');
            link.href = response.data;
            link.download = 'certificate';
            link.click();
          })
          .finally(() => {
            this.generatingUrl = false;
          });
      }, 1000);
    },

    deleteCert() {
      this.deleting = true;
      this.$store.dispatch('user/deleteExemptionCertificate', this.certificateId).finally(() => {
        this.deleting = false;
        this.$emit('deleted');
      });
    }
  }
};
</script>

<style scoped>
.v-btn.v-size--x-large {
  min-width: inherit;
}
</style>
