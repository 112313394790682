import Vue from 'vue';

import Vuetify from 'vuetify/lib';
import { COLORS } from '../utils/constants';

Vue.use(Vuetify, {});

const opts = {
  breakpoint: {
    mobileBreakpoint: 'xs',
    thresholds: {
      xs: 576,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1200
    }
  },
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    options: { customProperties: true },
    dark: false,
    themes: {
      light: {
        brand: COLORS.BRAND,
        primary: COLORS.PRIMARY,
        light: COLORS.LIGHT,
        error: COLORS.ERROR,
        success: COLORS.SUCCESS,
        warning: COLORS.WARNING,
        tan: COLORS.TAN,
        reviewStar: COLORS.REVIEW_STAR,
        green: COLORS.GREEN,
        orange: COLORS.ORANGE,
        background: COLORS.BACKGROUND
      }
    }
  }
};

export default new Vuetify(opts);
