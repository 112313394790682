<template>
  <div
    class="h-full d-flex flex-column"
    :style="{ width: expanded ? '418px' : '100%' }"
  >
    <div>
      <RoutesMap
        v-if="!expanded"
        id="audiencePreviewMap"
        height="300px"
        class="bordered border-light rounded-t"
        @expand="$emit('expand')"
        @search="getRoutes"
        @selected="scrollToItem"
      />
    </div>
    <div class="d-flex h-full flex-column autoscroll-y bordered border-light rounded-b">
      <div class="d-flex h-full flex-column autoscroll-y">
        <Loading
          v-if="loading"
          path="//res.cloudinary.com/mycreativeshop-blog/raw/upload/v1716519622/lf20_kd8pwfsg.json"
          :width="loadingDimension"
          :height="loadingDimension"
          title="Searching area"
          subtitle="We're preparing your target area, so you can select your audience."
        />
        <v-data-table
          v-else
          id="routesTable"
          class="flex-grow-1 hover-pointer d-flex h-full flex-column autoscroll-y"
          :loading="loading"
          :headers="headers"
          :items="items"
          item-key="zipRouteId"
          disable-pagination
          hide-default-footer
          fixed-header
          mobile-breakpoint="0"
          checkbox-color="black"
          :header-props="headerProps"
          :no-data-text="
            searchMade
              ? 'No routes available - Search again'
              : 'No routes - Search to find areas to target'
          "
          @click:row="selectRow"
        >
          <template #header.selected="{}">
            <v-icon @click="selectAll">
              {{ selectAllIcon }}
            </v-icon>
          </template>

          <template #item.selected="{ item }">
            <v-icon>
              {{
                selectedRoutes.filter((x) => x.zipRouteId == item.zipRouteId).length
                  ? checkedIcon
                  : uncheckedIcon
              }}
            </v-icon>
          </template>
        </v-data-table>
      </div>
      <v-divider />
      <div
        v-if="!$vuetify.breakpoint.mobile"
        class="pt-4 px-4"
      >
        <v-switch
          v-model="campaign.excludeBusinesses"
          :label="`Exclude ${business_address_count} businesses`"
          color="black"
          dense
          class="pt-0 mt-0"
          inset
          @change="updatePrice"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { uniqBy, union, cloneDeep, sumBy } from 'lodash';
import RoutesMap from '@/components/print/checkout/snailblast/routes/Map';
import { APP_EVENTS, DRAWERS, ANALYTICS_EVENTS } from '@/utils/constants';
import Loading from '@/components/print/checkout/snailblast/Loading';
import {
  mdiCheckboxBlankOutline,
  mdiCheckboxMarked,
  mdiUnfoldMoreHorizontal,
  mdiCheckboxIntermediate
} from '@mdi/js';
export default {
  name: 'SnailblastRoutes',
  components: {
    RoutesMap,
    Loading
  },
  props: { expanded: { type: Boolean, default: false } },
  data: () => ({
    uncheckedIcon: mdiCheckboxBlankOutline,
    checkedIcon: mdiCheckboxMarked,
    halfCheckedIcon: mdiCheckboxIntermediate,
    loading: false,
    filter: null,
    searchMade: false,
    headerProps: { 'sort-icon': mdiUnfoldMoreHorizontal },
    headers: [
      { text: '', value: 'selected' },
      { text: 'Route', value: 'zipRouteId' },
      { text: 'City', value: 'city' },
      { text: 'Total', value: 'totalCount' }
    ]
  }),
  computed: {
    selectAllIcon() {
      if (this.selectedRoutes.length) {
        if (this.selectedRoutes.length === this.items.length) {
          return this.checkedIcon;
        }
        return this.halfCheckedIcon;
      }
      return this.uncheckedIcon;
    },
    loadingDimension() {
      return this.$vuetify.breakpoint.mobile ? 100 : 150;
    },
    items() {
      const selectedRouteIds = this.selectedRoutes.map((x) => x.zipRouteId);

      const list = union(this.routes, this.selectedRoutes);

      return uniqBy(list, 'zipRouteId').map((x) =>
        Object.assign(x, { selected: selectedRouteIds.includes(x.zipRouteId) })
      );
    },
    campaign: {
      get() {
        return this.$store.getters['snailblast/campaign/edit_campaign'];
      },
      set(val) {
        this.$store.dispatch('snailblast/campaign/setEditCampaign', val);
      }
    },
    selectedRoutes: {
      get() {
        return cloneDeep(this.$store.getters['snailblast/routes/selected']);
      },
      set(val) {
        const ids = val.map((x) => x.zipRouteId).filter((x) => x);
        const features = this.items.filter((x) => ids.includes(x.zipRouteId));
        this.$store.dispatch('snailblast/routes/selectRoutesFromTable', features);
      }
    },
    business_address_count() {
      return sumBy(this.selectedRoutes, 'businessCount');
    },
    saving: {
      get() {
        return this.$store.getters['snailblast/campaign/saving'];
      },
      set(val) {
        this.$store.dispatch('snailblast/campaign/setSaving', val);
      }
    },
    ...mapGetters({
      routes: 'snailblast/routes/routes',
      address_count: 'snailblast/routes/address_count',
      loading_price: 'printcart/loading_price',
      price: 'printcart/price',
      analytics_data: 'snailblast/campaign/analytics_data'
    })
  },
  methods: {
    selectAll() {
      if (this.selectedRoutes.length && this.selectedRoutes.length === this.items.length) {
        this.selectedRoutes = [];
      } else {
        this.$mixpanel.trackEvent(
          ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
          ANALYTICS_EVENTS.MAILING_SERVICE.ACTIONS.ROUTE_SET,
          this.analytics_data
        );
        this.selectedRoutes = cloneDeep(this.routes);
      }
    },
    selectRow(item) {
      const index = this.selectedRoutes.findIndex((x) => x.zipRouteId === item.zipRouteId);
      if (index >= 0) {
        this.selectedRoutes = this.selectedRoutes.filter((x) => x.zipRouteId !== item.zipRouteId);
      } else {
        this.$mixpanel.trackEvent(
          ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
          ANALYTICS_EVENTS.MAILING_SERVICE.ACTIONS.ROUTE_SET,
          this.analytics_data
        );
        this.selectedRoutes = this.selectedRoutes.concat([item]);
      }
      this.updatePrice();
    },
    updatePrice() {
      this.$eventBus.$emit(APP_EVENTS.GET_EDIT_ITEM_PRICE, { source: 'routes' });
    },
    getRoutes(data) {
      this.$mixpanel.trackEvent(
        ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
        ANALYTICS_EVENTS.MAILING_SERVICE.ACTIONS.LOCATION_SET,
        this.analytics_data
      );
      this.loading = true;
      Promise.all([
        this.$store.dispatch('snailblast/routes/setMapCenter', data),
        this.$store.dispatch('snailblast/routes/getRadiusRoutes', data)
      ])
        .then(() => {
          this.searchMade = true;
        })
        .catch((error) => {
          this.logError(error, 'Error getting routes');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    save() {
      this.saving = true;
      this.$store
        .dispatch('snailblast/campaign/saveRoutes', {
          campaign: this.campaign,
          routes: this.selectedRoutes
        })
        .then(() => {
          this.$store.commit('drawers/CLOSE_DRAWER', DRAWERS.SNAILBLAST_AUDIENCE);
          this.$mixpanel.trackEvent(
            ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
            ANALYTICS_EVENTS.MAILING_SERVICE.ACTIONS.SAVE_COMPLETE,
            Object.assign(this.analytics_data, {
              mailing_service: 'eddm',
              audience_size: this.address_count(this.campaign.excludeBusinesses),
              price: this.price
            })
          );
          this.$store.dispatch('snailblast/campaign/trackSaveEvent', this.campaign);
        })
        .catch((error) => {
          this.logError(error, 'Error saving routes');
        })
        .finally(() => {
          this.saving = false;
        });
    },
    scrollToItem(item) {
      var contentEl = document
        .getElementById('routesTable')
        .querySelector('.v-data-table__wrapper');

      var rowEl = [...contentEl.querySelectorAll('td')].filter((x) =>
        x.textContent.includes(item.zipRouteId)
      )[0];

      if (contentEl && rowEl) contentEl.scrollTo({ top: rowEl.offsetTop - 48, behavior: 'smooth' });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table-header th .v-data-table-header__icon {
  transform: none !important;
  opacity: 1 !important;
  color: black !important;
}
</style>
