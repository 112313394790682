<template>
  <div class="w-full mt-4">
    <v-btn
      block
      outlined
      x-large
      @click="$emit('back')"
    >
      Go Back
    </v-btn>
    <div class="py-4">
      <div class="font-weight-bold">What can I upload?</div>
      <div>Acceptable file formats (CSV, EXL, XLSX)</div>
    </div>
    <v-divider />
    <div class="py-4">
      <div class="font-weight-bold">Do you have a template?</div>
      <div>
        Yes, we do!
        <a
          :href="downloadUrl"
          target="_blank"
        >
          Download template
          <v-icon
            small
            color="brand"
          >
            {{ downloadIcon }}
          </v-icon>
        </a>
      </div>
    </div>
    <v-divider />
    <div class="py-4">
      <div class="font-weight-bold">What fields are required?</div>
      <div>Read the descriptions below to understand your options.</div>
      <div class="pt-4">
        <span class="font-weight-bold">Address 1</span> - This is a
        <span class="text-decoration-underline">required</span> field. This should contain Street #
        and Street Name (May also contain info from Address 2)
      </div>
      <div class="pt-4">
        <span class="font-weight-bold">Address 2</span> - This is
        <span class="text-decoration-underline">not required</span>, but could be used for Apartment
        or Suite info.
      </div>
      <div class="pt-4">
        <span class="font-weight-bold">First or Full Name, Last Name, Company or Message</span> -
        <span class="text-decoration-underline">At least one of these fields is required</span>.
        This can be a write in Message (i.e. CURRENT RESIDENT).
      </div>
      <div class="pt-4">
        <span class="font-weight-bold">City, State, Zip</span> -
        <span class="text-decoration-underline">All of these are required</span>. State can be
        abbreviated or written out.
      </div>
    </div>
    <v-divider />
    <div class="py-4">
      Need help?
      <span
        class="brand--text hover-pointer"
        @click="showHelpScout"
        >Message Us</span
      >
    </div>
  </div>
</template>

<script>
import { LINKS, APP_EVENTS, ANALYTICS_EVENTS } from '@/utils/constants';
import { mdiTrayArrowDown } from '@mdi/js';
import { mapGetters } from 'vuex';
export default {
  name: 'SnailblastMailingListGuide',
  data: () => ({
    downloadUrl: LINKS.SNAILBLAST_TEMPLATE,
    downloadIcon: mdiTrayArrowDown
  }),
  computed: {
    ...mapGetters({ analytics_data: 'snailblast/campaign/analytics_data' })
  },
  mounted() {
    this.$mixpanel.trackEvent(
      ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
      null,
      Object.assign(this.analytics_data, {
        screen: ANALYTICS_EVENTS.MAILING_SERVICE.SCREENS.HELP_GUIDE
      })
    );
  },
  methods: {
    showHelpScout() {
      this.$eventBus.$emit(APP_EVENTS.OPEN_HELPSCOUT, { ask: true });
    }
  }
};
</script>
