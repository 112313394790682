<template>
  <Drawer
    :id="drawerId"
    :title="title"
    :titleIcon="fileIcon"
    preloadContent
    bottom
    height="75%"
    padded
    class="rounded-t-xl"
    closeGoesBack
    hideBack
    :customBackFunc="customBackFunc()"
    :loading="loading"
  >
    <div>
      <CertificateManagementView
        v-show="showManagementView"
        @goDetails="goDetails"
      />
      <CertificateDetailsView
        v-if="showDetailsView"
        :id="selectedCertificateId"
        @home="goHome"
      />
    </div>
  </Drawer>
</template>

<script>
import { DRAWERS } from '@/utils/constants';
import Drawer from '@/components/layout/Drawer';
import CertificateManagementView from './CertificateManagementView';
import CertificateDetailsView from './CertificateDetailsView';
import { EXEMPTION_CERTIFICATE_VIEWS } from '../../../utils/constants';
import { mdiFileDocument } from '@mdi/js';
import { mapGetters } from 'vuex';

export default {
  name: 'ExemptionCertificatesDrawer',

  components: {
    Drawer,
    CertificateManagementView,
    CertificateDetailsView
  },

  data() {
    return {
      drawerId: DRAWERS.EXEMPTION_CERTIFICATES_DRAWER,
      activeView: EXEMPTION_CERTIFICATE_VIEWS.MANAGEMENT,
      selectedCertificateId: 0,
      fileIcon: mdiFileDocument,
      loading: false
    };
  },

  computed: {
    title() {
      if (this.activeView === EXEMPTION_CERTIFICATE_VIEWS.MANAGEMENT) { return 'Tax Exempt Certificates'; } else return 'Certificate Details';
    },

    showManagementView() {
      return this.activeView === EXEMPTION_CERTIFICATE_VIEWS.MANAGEMENT;
    },

    showDetailsView() {
      return this.activeView === EXEMPTION_CERTIFICATE_VIEWS.DETAILS;
    },

    ...mapGetters({
      initialData: 'drawers/data',
      drawerOpenedAt: 'drawers/lastOpenedAt'
    })
  },

  watch: {
    drawerOpenedAt() {
      if (this.drawerId === this.$store.getters['drawers/topDrawer']?.id) {
        this.activeView = EXEMPTION_CERTIFICATE_VIEWS.MANAGEMENT;
        this.loading = true;
        this.$store.dispatch('user/refreshProfile').finally(() => {
          this.loading = false;
        });
      }
    }
  },

  methods: {
    goHome() {
      this.activeView = EXEMPTION_CERTIFICATE_VIEWS.MANAGEMENT;
    },

    goDetails(id) {
      this.activeView = EXEMPTION_CERTIFICATE_VIEWS.DETAILS;
      this.selectedCertificateId = id;
    },

    customBackFunc() {
      if (this.showDetailsView) {
        return () => {
          this.activeView = EXEMPTION_CERTIFICATE_VIEWS.MANAGEMENT;
        };
      } else {
        return null;
      }
    }
  }
};
</script>

<style scoped></style>
