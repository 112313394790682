<template>
  <div class="bordered border-light rounded mt-4 pa-4">
    <div class="font-weight-bold">
      <v-icon
        class="mr-1"
        small
      >
        {{ mailIcon }}
      </v-icon>
      {{ messageSent ? 'Message sent' : 'Old Editor Detected' }}
    </div>
    <div class="subtitle-2 mt-3 mb-4">
      <template v-if="messageSent">
        A message has been sent to our team and we will email you once the design has been converted
        to the new editor (Average response is ~8hrs)
      </template>
      <ul
        v-else
        class="ml-n2"
      >
        <li>We need to convert your design to the new editor</li>
        <li>A designer from our team can fix this!</li>
        <li>We'll create a copy of your design. No work is lost!</li>
      </ul>
    </div>
    <div class="my-3">
      <v-btn
        block
        :outlined="!messageSent"
        :disabled="messageSent"
        large
        :loading="loading"
        @click="sendMessage"
      >
        {{ messageSent ? 'Sent' : 'Convert to new editor (free)' }}
      </v-btn>
    </div>
    <div>
      <v-btn
        block
        text
        color="brand"
        @click="skip"
      >
        Skip for now and just view pricing
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mdiSend } from '@mdi/js';
import snailblast from '@/utils/mixins/snailblast';
import { APP_EVENTS, ANALYTICS_EVENTS } from '@/utils/constants';
export default {
  name: 'EditItemOldEditorCheck',
  mixins: [snailblast],
  props: { mailingOption: { type: Object, default: () => {} } },
  data: () => ({
    mailIcon: mdiSend,
    loading: false,
    messageSent: false
  }),
  computed: {
    edit_item: {
      get() {
        return this.$store.getters['printcart/edit_item'];
      },
      set(val) {
        this.$store.dispatch('printcart/setEditItem', val);
      }
    },
    ...mapGetters({
      editor_url: 'printcart/editor_url',
      core_product: 'templates/core_product',
      edit_item: 'printcart/edit_item',
      analytics_data: 'snailblast/campaign/analytics_data'
    })
  },
  mounted() {
    this.messageSent = false;
  },
  methods: {
    skip() {
      this.$store
        .dispatch('printcart/setEditItem', {
          coreProductId: this.mailingOption.defaultCoreProductId,
          project: {}
        })
        .then(() => {
          this.$eventBus.$emit(APP_EVENTS.EDIT_ITEM_LOAD_DATA, {});
        });
    },
    sendMessage() {
      this.$mixpanel.trackEvent(
        ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
        ANALYTICS_EVENTS.MAILING_SERVICE.ACTIONS.FIX_CHILI,
        this.analytics_data
      );
      this.loading = true;
      this.$store
        .dispatch('printcart/sendEditorMessage', {})
        .then(() => {
          this.messageSent = true;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
