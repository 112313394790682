<template>
  <div
    class="border-l border-t border-light pa-4 mt-8"
    style="background-color: #f8f8f8; border-top-left-radius: 12px"
  >
    © {{ year }} {{ name }}, All rights reserved.
  </div>
</template>

<script>
export default {
  name: 'Footer',

  components: {},

  data() {
    return {
      year: ''
    };
  },

  computed: {
    name() {
      return this.$store.state.user.masterAccount.name;
    }
  },

  mounted() {
    this.year = new Date().getFullYear();
  },

  methods: {}
};
</script>

<style></style>
