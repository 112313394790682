<template>
  <div class="light d-flex justify-space-between">
    <div class="my-auto pl-2">
      <Label :field="field" />
    </div>
    <div class="pa-2">
      <v-btn
        color="white"
        class="black--text mr-2"
        @click="$emit('back')"
      >
        Back
      </v-btn>
      <v-btn
        color="green"
        class="white--text"
        @click="$emit('save')"
      >
        Save
      </v-btn>
    </div>
  </div>
</template>

<script>
import Label from '@/components/print/checkout/snailblast/listpurchase/filters/field/Label';
export default {
  name: 'SnailblastListPurchaseFiltersFieldHeader',
  components: { Label },
  props: { field: { type: Object, default: () => {} } }
};
</script>
