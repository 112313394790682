<template>
  <v-sheet class="bordered border-light rounded overflow-hidden">
    <v-btn
      class="float-right"
      color="black"
      icon
      :loading="deleting"
      @click="deleteItem(item)"
    >
      <v-icon size="24">
        {{ closeIcon }}
      </v-icon>
    </v-btn>
    <v-row class="pa-2">
      <v-col
        cols="auto"
        class="my-auto"
      >
        <v-img
          :src="item.project.previewUrl"
          width="100"
          contain
        />
      </v-col>
      <v-col>
        <div class="body-1 font-weight-bold">
          {{ projectName || 'Unnamed project' }}
        </div>
        <div class="body-2 mt-2">
          <div>
            Qty {{ (item.campaign ? item.campaign.recipientCount : item.quantity) | separator }}
          </div>
          <div>{{ item.project.coreProductName }}</div>
          <div>
            {{
              material(item.materialId)
                .names.filter((x) => x.categoryId == item.project.categoryId)
                .map((x) => x.text)[0] || material(item.materialId).display
            }}
          </div>
          <template v-if="item.addressId">
            <div class="font-weight-bold mt-2">Ship to:</div>
            <div>
              {{ address(item.addressId).firstName }} {{ address(item.addressId).lastName }}
            </div>
            <div v-if="address(item.addressId).company">
              {{ address(item.addressId).company }}
            </div>
            <div>
              {{ address(item.addressId).line1 }} {{ address(item.addressId).city }}
              {{ address(item.addressId).state }}, {{ address(item.addressId).postalCode }}
            </div>
          </template>
          <div
            v-if="item.campaign"
            class="mt-2"
          >
            <div class="font-weight-bold">Print & mail to:</div>
            <div>{{ item.campaign.recipientCount | separator }} addresses</div>
          </div>
          <div
            v-if="item.delivery"
            class="mt-2"
          >
            <template v-if="item.campaign">
              <div class="font-weight-bold">Expected In-Mailboxes:</div>
              <div v-if="item.delivery.valid">
                {{ scheduledRange }}
                <v-icon
                  color="green"
                  class="ml-2"
                  small
                >
                  {{ checkIcon }}
                </v-icon>
              </div>
              <div
                v-else
                class="reviewStar--text hover-pointer"
                @click="$emit('edit', item)"
              >
                <span class="text-decoration-underline">Select new mailbox date</span>
                <v-icon
                  color="reviewStar"
                  class="ml-2"
                  small
                >
                  {{ errorIcon }}
                </v-icon>
              </div>
            </template>
            <template v-else>
              <div class="font-weight-bold">Estimated Delivery:</div>
              <div v-if="item.delivery.valid">
                {{ item.delivery.earliestDate | regularDateNoYear }} -
                {{ item.delivery.latestDate | regularDateNoYear }}
                <v-icon
                  color="green"
                  class="ml-2"
                  small
                >
                  {{ checkIcon }}
                </v-icon>
              </div>
              <div
                v-else
                class="reviewStar--text hover-pointer"
                @click="$emit('edit-shipping')"
              >
                <span class="text-decoration-underline">Select new delivery date</span>
                <v-icon
                  color="reviewStar"
                  class="ml-2"
                  small
                >
                  {{ errorIcon }}
                </v-icon>
              </div>
            </template>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-divider />
    <v-row>
      <v-col>
        <v-btn
          text
          color="primary"
          @click="$emit('edit')"
        >
          Make Changes
        </v-btn>
      </v-col>
      <v-spacer />
      <v-col
        cols="auto"
        class="my-auto mr-4 body-1"
      >
        <div class="d-flex align-center font-weight-bold">
          <div
            v-if="item.subTotalPriceOriginal > item.subTotalPrice"
            class="text-decoration-line-through mr-2"
          >
            {{ item.subTotalPriceOriginal | currency }}
          </div>
          <div :class="{ 'green--text': item.subTotalPriceOriginal > item.subTotalPrice }">
            {{ item.subTotalPrice | currency }}
          </div>
          <v-icon
            v-if="allowCosts"
            class="ml-2 hover-pointer"
            size="24"
            @click="showCosts = !showCosts"
          >
            {{ eyeIcon }}
          </v-icon>
        </div>
      </v-col>
    </v-row>
    <v-expand-transition v-if="allowCosts">
      <v-row
        v-show="showCosts"
        class="mt-0 px-4 body-2 mb-3"
        dense
      >
        <v-col
          cols="auto"
          class="d-flex justify-space-between w-full border-t border-light pt-4 mt-2"
        >
          <div><b>Print:</b> {{ item.baseCost | currency }}</div>
          <div v-if="item.delivery.turnaroundCost">
            <b>Turnaround:</b> {{ item.delivery.turnaroundCost | currency }}
          </div>
          <div v-if="item.postageCost"><b>Postage:</b> {{ item.postageCost | currency }}</div>
          <div v-if="item.listPurchaseCost">
            <b>List Purchase:</b> {{ item.listPurchaseCost | currency }}
          </div>
          <div v-if="item.delivery.shippingCost">
            <b>Shipping:</b> {{ item.delivery.shippingCost | currency }}
          </div>
          <div v-if="item.delivery.salesTaxCost">
            <b>Taxes:</b> {{ item.delivery.salesTaxCost | currency }}
          </div>
          <div>
            <b>Profit:</b>
            {{
              (item.subTotalPrice -
                item.baseCost -
                item.delivery.turnaroundCost -
                item.delivery.shippingCost -
                item.delivery.salesTaxCost -
                item.postageCost -
                item.listPurchaseCost)
                | currency
            }}
          </div>
        </v-col>
      </v-row>
    </v-expand-transition>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex';
import { mdiClose, mdiCheckCircle, mdiAlertCircle, mdiEyeOutline } from '@mdi/js';
import {} from '@/utils/constants';
import snailblast from '@/utils/mixins/snailblast';

export default {
  name: 'PrintCart',

  components: {},

  mixins: [snailblast],

  props: {
    item: { type: Object, default: () => {} }
  },

  data: () => ({
    deleting: false,
    closeIcon: mdiClose,
    checkIcon: mdiCheckCircle,
    errorIcon: mdiAlertCircle,
    eyeIcon: mdiEyeOutline,
    showCosts: false
  }),

  computed: {
    address() {
      return (addressId) => {
        return this.addresses.find((x) => x.id === addressId) || {};
      };
    },
    allowCosts() {
      return this.isAdmin || !!this.runas;
    },
    scheduledRange() {
      return this.getScheduleRangeFormatted(this.item.delivery.earliestDate);
    },
    projectName() {
      return this.item.name?.replace('snapshot of ', '');
    },

    ...mapGetters({
      cart: 'printcart/cart',
      material: 'lookups/material',
      addresses: 'user/addresses',
      project: 'projects/project',
      isAdmin: 'user/isAdmin',
      runas: 'config/runas'
    })
  },

  methods: {
    deleteItem() {
      this.deleting = true;
      this.$store
        .dispatch('printcart/deleteItem', this.item.id)
        .then(() => {
          this.$emit('deleted');
        })
        .catch((error) => {
          this.$(error);
          this.$root.$alert('Error Occurred', 'An error occured while removing an item.');
        })
        .finally(() => {
          this.deleting = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped></style>
