export const ROUTES = {
  DASHBOARD: {
    NAME: 'dashboard',
    DISPLAY: 'Dashboard',
    PATH: '/'
  },
  PROJECTS: {
    NAME: 'projects',
    DISPLAY: 'Projects',
    PATH: '/projects'
  },
  PROJECT_DETAIL: {
    NAME: 'project-detail',
    DISPLAY: 'Details',
    PATH: '/project/detail'
  },
  PRINT: {
    NAME: 'print',
    DISPLAY: 'Printing',
    PATH: '/print'
  },
  SUBSCRIPTION: {
    NAME: 'subscription',
    DISPLAY: 'Subscription',
    PATH: '/subscription'
  },
  PRINT_CHECKOUT: {
    NAME: 'print-checkout',
    DISPLAY: 'Print Checkout',
    PATH: '/checkout/print'
  },
  ORDER_HISTORY: {
    NAME: 'order-history',
    DISPLAY: 'Order History',
    PATH: '/orders'
  },
  ORDER_DETAIL: {
    NAME: 'order-detail',
    DISPLAY: 'Order Detail',
    PATH: '/orders/detail'
  },
  CARDS: {
    NAME: 'cards',
    DISPLAY: 'Payment Methods',
    PATH: '/cards'
  },
  PROFILE: {
    NAME: 'profile',
    DISPLAY: 'Profile',
    PATH: '/profile'
  },
  CONTRIBUTOR_DESIGN_PROJECTS: {
    NAME: 'contributor-design-projects',
    DISPLAY: 'Projects',
    PATH: '/contributor/design-projects'
  },
  CONTRIBUTOR_ASSET_PROJECTS: {
    NAME: 'contributor-asset-projects',
    DISPLAY: 'Projects',
    PATH: '/contibutor/asset-projects'
  },
  CONTRIBUTOR_IMAGE_PROJECTS: {
    NAME: 'contributor-image-projects',
    DISPLAY: 'Projects',
    PATH: '/contibutor/image-projects'
  },
  CONTRIBUTOR_CUSTOMER_PROJECTS: {
    NAME: 'contributor-customer-projects',
    DISPLAY: 'Projects',
    PATH: '/contributor/customer-projects'
  },
  CONTRIBUTOR_PAYOUTS: {
    NAME: 'contributor-payouts',
    DISPLAY: 'Payouts',
    PATH: '/contributor/payouts'
  },
  CAMPAIGN_IDEAS: {
    NAME: 'campaign-ideas',
    DISPLAY: 'Campaign Ideas',
    PATH: '/campaign-ideas'
  },
  ADMIN_BANKING: {
    NAME: 'admin-banking',
    DISPLAY: 'Banking',
    PATH: '/admin/banking'
  },
  ADMIN_SETTINGS: {
    NAME: 'admin-settings',
    DISPLAY: 'App Settings',
    PATH: '/admin/settings'
  },
  ADMIN_THEME: {
    NAME: 'admin-theme',
    DISPLAY: 'Theme',
    PATH: '/admin/theme'
  },
  ADMIN_PRODUCT_SETUP: {
    NAME: 'admin-product-setup',
    DISPLAY: 'Product Setup',
    PATH: '/admin/product-setup'
  },
  ADMIN_PEOPLE: {
    NAME: 'admin-people',
    DISPLAY: 'People',
    PATH: '/admin/people'
  },
  ADMIN_PEOPLE_DETAIL: {
    NAME: 'admin-people-detail',
    DISPLAY: 'People Detail',
    PATH: '/admin/people/:userId'
  },
  ADMIN_COLLECTIONS_SETUP: {
    NAME: 'admin-collections-setup',
    DISPLAY: 'Collections',
    PATH: '/admin/collections'
  },
  ADMIN_COLLECTION_TEMPLATE_SETUP: {
    NAME: 'admin-collection-template-setup',
    DISPLAY: 'Templates',
    PATH: '/admin/collections/templates'
  },
  ADMIN_COLLECTION_TEMPLATE_CHILD_SETUP: {
    NAME: 'admin-collection-template-setup-child',
    DISPLAY: 'Templates',
    PATH: '/admin/collections/templates/:parentId'
  },
  ADMIN_COLLECTION_IMAGE_SETUP: {
    NAME: 'admin-collection-image-setup',
    DISPLAY: 'Images',
    PATH: '/admin/collections/images'
  },
  ADMIN_COLLECTION_IMAGE_CHILD_SETUP: {
    NAME: 'admin-collection-image-setup-child',
    DISPLAY: 'Images',
    PATH: '/admin/collections/images/:parentId'
  },
  ADMIN_COLLECTION_COLOR_SETUP: {
    NAME: 'admin-collection-color-setup',
    DISPLAY: 'Colors',
    PATH: '/admin/collections/colors'
  },
  ADMIN_SETUP_INDUSTRIES: {
    NAME: 'admin-product-setup-industries',
    DISPLAY: 'Industry',
    PATH: '/admin/product-setup/industries'
  },
  ADMIN_SETUP_CATEGORY: {
    NAME: 'admin-product-setup-category',
    DISPLAY: 'Category',
    PATH: '/admin/product-setup/category/:categoryId'
  },
  ADMIN_SETUP_COREPRODUCT: {
    NAME: 'admin-setup-coreproduct',
    DISPLAY: 'Print',
    PATH: '/admin/product-setup/category/:categoryId/products/:productId'
  },
  ADMIN_SETUP_MATERIAL: {
    NAME: 'admin-setup-material',
    DISPLAY: 'Product',
    PATH: '/admin/product-setup/category/:categoryId/products/:productId/materials/:materialId'
  },
  ADMIN_MASTER_ACCOUNTS: {
    NAME: 'admin-master-accounts',
    DISPLAY: 'Master Accounts',
    PATH: '/admin/master-accounts'
  },
  ADMIN_ORDERS_PAYOUTS: {
    NAME: 'admin-orders-payouts',
    DISPLAY: 'Payouts',
    PATH: '/admin/orders/payouts'
  },
  ADMIN_EXEMPTION_CERTS: {
    NAME: 'admin-exemption-certs',
    DISPLAY: 'Exemption Certificates',
    PATH: '/admin/tax-exempt-certificates'
  },
  ADMIN_APP_LINKS: {
    NAME: 'admin-app-links',
    DISPLAY: 'App Links',
    PATH: '/admin/app-links'
  },
  ADMIN_API_DOCS: {
    NAME: 'admin-api-docs',
    DISPLAY: 'Api Documentation',
    PATH: '/admin/api/:id'
  },
  PRINT_CHECKOUT_IFRAME: {
    NAME: 'print-checkout-iframe',
    PATH: '/print-checkout-iframe'
  },
  SUBSCRIPTION_CHECKOUT_IFRAME: {
    NAME: 'subscription-checkout-iframe',
    PATH: '/subscription-checkout-iframe'
  },
  ADMIN_ORDERS: {
    NAME: 'admin-orders',
    DISPLAY: 'Admin Orders',
    PATH: '/admin/orders'
  },
  NOTFOUND: {
    NAME: 'not-found',
    PATH: '/not-found'
  }
};

export const COLORS = {
  BRAND: '#4F46E5',
  PRIMARY: '#4F46E5',
  LIGHT: '#F8F8F8',
  ERROR: '#FF5252',
  SUCCESS: '#57BB89',
  WARNING: '#FFE191',
  TAN: '#F6E7C0',
  REVIEW_STAR: '#FBB040',
  GREEN: '#4CAA3A',
  ORANGE: '#FFA913',
  BACKGROUND: '#F5F5F5'
};

export const DRAWERS = {
  PROFILE: 'profileDrawer',
  SUBSCRIPTION_CHECKOUT: 'subscriptionCheckoutDrawer',
  PRINT_CHECKOUT: 'printCheckoutDrawer',
  ADD_CARD_DRAWER: 'addCardDrawer',
  UPDATE_CARD_DRAWER: 'updateCardDrawer',
  CATALOG_DRAWER: 'catalogDrawer',
  CONTRIBUTOR_DESIGN_PROJECT_DRAWER: 'contributorDesignProjectDrawer',
  CONTRIBUTOR_ASSET_PROJECT_DRAWER: 'contributorAssetProjectDrawer',
  CONTRIBUTOR_IMAGE_PROJECT_DRAWER: 'contributorImageProjectDrawer',
  RETRY_PAYMENT_DRAWER: 'retryPaymentDrawer',
  REACTIVATE_SUBSCRIPTION_DRAWER: 'reactivateSubscriptionDrawer',
  CANCEL_SUBSCRIPTION_DRAWER: 'cancelSubscriptionDrawer',
  SET_DEFAULT_PAYMENT_DRAWER: 'setDefaultPaymentDrawer',
  NON_PROFIT_DRAWER: 'nonProfitDrawer',
  EXEMPTION_CERTIFICATES_DRAWER: 'exemptionCertificatesDrawer',
  ADMIN_EXEMPTION_CERTIFICATES_DRAWER: 'adminExemptionCertificatesDrawer',
  PRINT_SELECT_PROJECT: 'printSelectProject',
  PRINT_PREVIEW: 'printPreviewDrawer',
  REVIEW: 'reviewDrawer',
  EDITOR_DRAWER: 'editorDrawer',
  PRINT_CHECKOUT_SHIPPING: 'printCheckoutShippingDrawer',
  SNAILBLAST_AUDIENCE: 'snailblastAudience',
  SNAILBLAST_UPLOAD_ADDRESS_EDIT: 'snailblastUploadAddressEdit',
  MANAGE_ADDRESSES: 'manageAddresses',
  MARGIN_OPTIONS: 'marginOptions',
  CONTRIBUTOR_CUSTOMER_PROJECT_NOTIFY_DRAWER: 'contributorCustomerProjectNotifyDrawer',
  ADD_COLLECTION_TEMPLATE: 'addCollectionTemplate',
  EDIT_COLLECTION_TEMPLATE: 'editCollectionTemplate',
  EDIT_COLLECTION_IMAGE: 'editCollectionImage',
  EDIT_PEOPLE: 'editPeople',
  EDIT_MASTER_ACCOUNT: 'editMasterAccount',
  ADD_PEOPLE: 'addPeople',
  EDIT_CAMPAIGN_IDEA: 'editCampaignIdea',
  EDIT_CAMPAIGN_IDEA_FILTERS: 'editCampaignIdeaFilters',
  EDIT_CAMPAIGN_IDEA_TEMPLATES: 'editCampaignIdeaTemplates',
  SELECT_CAMPAIGN_IDEA: 'selectCampaignIdea',
  ADMIN_INVOICE: 'adminInvoice',
  SAVE_PROJECT_AS_TEMPLATE: 'saveProjectAsTemplate',
  INVOICE_CHECKOUT: 'invoiceCheckout',
  EDIT_COLLECTION: 'editCollection',
  SELECT_PEOPLE: 'selectPeople',
  EDIT_FOLDER: 'editFolder',
  ADD_COLOR: 'addColor',
  UPLOAD_IMAGES: 'uploadImages'
};

export const PRINT_CHECKOUT_SCREENS = {
  PROJECTS: 'printCheckoutProjects',
  CART: 'printCheckoutCart',
  EDIT: 'printCheckoutEdit',
  CHECKOUT: 'printCheckout',
  CONFIRMATION: 'printCheckoutConfirmation'
};

export const EXEMPTION_CERTIFICATE_VIEWS = {
  MANAGEMENT: 'management',
  DETAILS: 'details'
};

export const EXEMPTION_CERTIFICATE_STATUS = {
  APPROVED: 1,
  UNDER_REVIEW: 2,
  REJECTED: 3
};
export const APP_EVENTS = {
  APP_DATA_READY: 'appDataReady',
  REFRESH_PRINT_DRAWER: 'refreshPrintDrawer',
  REFRESH_PROJECT_DETAIL: 'refreshProjectDetail',
  REFRESH_SELECTED_PRINT_PROJECT: 'refreshSelectedPrintProject',
  OPEN_HELPSCOUT: 'openHelpscout',
  SET_NAV_VISIBILITY: 'setNavVisibility',
  SNACKBAR_MOUNTED: 'snackbarMounted',
  SHOW_PROOF_OVERLAY: 'showProofOverlay',
  SHOW_IMAGE_OVERLAY: 'showImageOverlay',
  GET_EDIT_ITEM_PRICE: 'getEditItemPrice',
  GET_LIST_PURCHASE_COUNT: 'getListPurchaseCount',
  TAX_EXEMPT_CERT_UPDATED: 'taxExemptCertUpdated',
  EDIT_ITEM_LOAD_DATA: 'editItemLoadData',
  SET_PAYMENT_METHOD: 'setPaymentMethod',
  REFRESH_MASTER_ACCOUNTS: 'refreshMasterAccounts',
  REFRESH_PEOPLE: 'refreshPeople',
  IDEA_FILTERS_SET: 'ideaFiltersSet',
  IDEA_TEMPLATES_SET: 'ideaTemplatesSet',
  PEOPLE_SELECTED: 'peopleSelected'
};

export const DESIGN_PROJECT_STATUS = {
  PENDING: 1,
  OPEN: 2,
  ASSIGNED: 3,
  UNDER_REVIEW: 4,
  COMPLETE: 5
};

export const ASSET_PROJECT_STATUS = {
  OPEN: 1,
  ASSIGNED: 2,
  UNDER_REVIEW: 3,
  PUBLISHED: 4
};

export const POSTMESSAGE_EVENTS = {
  CLOSE_IFRAMED_EDITOR: 'mcs_editor-close-iframed-editor',
  CREATE_PROJECT: 'create-project',
  IFRAMED_PRINT_DRAWER_OPENED: 'iframed-print-drawer-opened',
  IFRAMED_PRINT_PROJECT_APPROVED: 'iframed-print-project-approved',
  IFRAMED_PRINT_UPDATE_PREVIEW: 'iframed-print-update-preview',
  IFRAMED_SNAILBLAST_REVIEW_COMPLETE: 'iframed-snailblast-review-complete',
  OPEN_URL: 'mcs_editor-open-url'
};

export const ANALYTICS_EVENTS = {
  DRAWER_OPENED: 'drawer_opened',
  LIMITATION: {
    NAME: 'limitation'
    // can't use actions for this event because we want to mirror the limitation events in the editor
    // these events don't use actions, they pass a null action and use an object with a message property
  },
  PAYMENT: {
    NAME: 'dashboard_payment',
    ACTIONS: {
      SETUP_PAYMENT_START: 'setup_payment_start',
      SETUP_PAYMENT_SUCCESS: 'setup_payment_success',
      SETUP_PAYMENT_ERROR: 'setup_payment_error'
    }
  },
  SUBSCRIBE: {
    NAME: 'dashboard_subscribe',
    ACTIONS: {
      SUBSCRIBE_START: 'start',
      SUBSCRIBE_SUCCESS: 'success',
      SUBSCRIBE_SUCCESS_IFRAME: 'success_iframe',
      SUBSCRIBE_ERROR: 'error'
    }
  },
  PRINT: {
    NAME: 'dashboard_print',
    ACTIONS: {
      CART_ERROR: 'cart_error',
      ORDER_START: 'order_start',
      ORDER_SUCCESS: 'order_success',
      ORDER_ERROR: 'order_error'
    }
  },
  MAILING_SERVICE: {
    NAME: 'mailing_service',
    ACTIONS: {
      FIX_FREEDOM: 'fix_freedom',
      FIX_CHILI: 'fix_chili',
      NEW: 'new',
      EXISTING: 'existing',
      SAVE_FORMAT: 'save_format',
      SAVE_COMPLETE: 'save_complete',
      LIST_TYPE_SET: 'list_type_set',
      LOCATION_SET: 'location_set',
      FILTER_SET: 'filter_set',
      MAP_EXPAND: 'map_expand',
      ROUTE_SET: 'route_set'
    },
    SCREENS: {
      START: 'start',
      FORMAT: 'format',
      FORMAT_PREVIEW: 'format_preview',
      HELP_GUIDE: 'help_guide',
      UPLOAD_OVERVIEW: 'upload_overview',
      UPLOAD_VIEW_LIST: 'upload_view_list'
    },
    TYPE: { EDDM: 'eddm', UPLOAD: 'upload', LIST_BUILDER: 'list_builder' }
  },
  EDITOR_BLOCKED: {
    NAME: 'editor_blocked',
    ACTIONS: {
      SUBSCRIPTION_REQUIRED: 'subscription_required',
      SUBSCRIPTION_CANCELED: 'subscription_canceled',
      SUBSCRIPTION_PASTDUE: 'subscription_pastdue',
      ACCESS_DENIED: 'access_denied' // should hopefully never see this one
    }
  }
};

export const ENUMS = {
  SNAILBLAST: {
    CAMPAIGN_TYPE: {
      EDDM: 1,
      UPLOAD: 2,
      LIST_PURCHASE: 3
    },
    MAP_STYLES: {
      STREETS: 'mapbox://styles/mapbox/streets-v12',
      SATELLITES: 'mapbox://styles/mapbox/satellite-v9'
    },
    LIST_PURCHASE_DATABASE: {
      BUSINESS: 1,
      NEW_BUSINESS: 2,
      CONSUMER: 3,
      NEW_MOVER: 4,
      NEW_HOMEOWNER: 5
    },
    LIST_PURCHASE_FIELD_TYPES: {
      TEXT: 1,
      SELECT: 2,
      MONTH_DATE: 3,
      BOOL: 4,
      YEAR_RANGE: 5,
      DROPDOWN: 6
    }
  },
  UNITS: { INCHES: 1, MILLIMETERS: 2, PIXELS: 4 },
  BLEED: {
    INCHES: 0.125,
    MILLIMETERS: 3.175
  },
  LOCAL: {
    // HIDE_WELCOME: 'hide-welcome',
    // HIDE_WELCOME_CARD: 'hide-[type]-welcome-card',
    // HIDE_SNAILBLAST_HEADER: 'hide-snailblast-header',
    HIDE_HEADER_CARD: 'hide-[type]-header-card',
    HIDE_ALERT: 'hide-[type]-alert'
    // HIDE_ADD_ON_ALERT: 'hide-add-on-[add_on]-alert',
    // SSO_TOKEN: 'eisdkegt'
  }
};

export const LINKS = {
  SNAILBLAST_TEMPLATE:
    'https://res.cloudinary.com/snail-blast/raw/upload/v1686860919/Resources/Spray-Net_Upload_Full_re8kcp.csv'
};
