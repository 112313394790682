<template>
  <Drawer
    :id="drawerId"
    title="Create A New Project"
    width="95%"
  >
    <v-sheet
      v-if="catalogUrl"
      class="d-flex h-full"
    >
      <iframe
        :src="catalogUrl"
        class="w-full h-full"
        style="border: none"
      />
    </v-sheet>
  </Drawer>
</template>

<script>
import { DRAWERS } from '../../utils/constants';
import Drawer from '../../components/layout/Drawer';
import { mapGetters } from 'vuex';

export default {
  name: 'CatalogDrawer',

  components: {
    Drawer
  },

  props: {
    iframed: Boolean
  },

  data() {
    return {
      drawerId: DRAWERS.CATALOG_DRAWER,
      everRendered: false,
      catalogUrl: ''
    };
  },

  computed: {
    ...mapGetters({
      initialData: 'drawers/data',
      drawerOpenedAt: 'drawers/lastOpenedAt'
    })
  },

  watch: {
    drawerOpenedAt(val, oldVal) {
      if (this.drawerId === this.$store.getters['drawers/topDrawer']?.id) {
        var url =
          this.initialData(this.drawerId).catalogUrl || this.$store.getters['config/catalogUrl'];
        this.catalogUrl = url + '?embedded=true';
      }
    }
  },

  methods: {}
};
</script>
