import { getLocal, setLocal } from '../local';
export default {
  methods: {
    setLocal(key, val) {
      setLocal(key, val);
    },
    getLocal(key, val) {
      getLocal(key, val);
    }
  }
};
