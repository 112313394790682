<template>
  <v-sheet class="bordered border-light rounded my-4 px-5 py-4">
    <div class="d-flex justify-space-between align-center mb-4">
      <div class="d-flex align-center">
        <v-icon>{{ creditCardIcon }}</v-icon>
        <div class="font-weight-bold ml-2">Payment</div>
      </div>
      <div
        v-if="cards.length"
        class="font-weight-bold primary--text hover-pointer"
        @click="add"
      >
        Add New Card
      </div>
    </div>
    <v-select
      v-if="cards.length > 0"
      v-model="selectionId"
      :items="cards"
      item-value="id"
      item-text="display"
      hide-details
      outlined
      label="Set Payment Method"
      persistent-placeholder
      @change="$emit('paymentMethodSelected', selectionId)"
    >
      <template #append-outer>
        <v-btn
          icon
          @click="edit"
        >
          <v-icon>{{ editIcon }}</v-icon>
        </v-btn>
      </template>
    </v-select>
    <v-btn
      v-else
      outlined
      style="border: solid 1.5px"
      x-large
      block
      elevation="0"
      :loading="loading"
      @click="add"
    >
      <v-icon class="mr-2">
        {{ addIcon }}
      </v-icon>
      Debit / Credit Card
    </v-btn>
  </v-sheet>
</template>

<script>
import { APP_EVENTS, DRAWERS } from '../../utils/constants';
import { mdiCreditCardEditOutline, mdiCreditCardOutline, mdiPlusCircle } from '@mdi/js';

export default {
  name: 'PaymentSelector',

  props: {
    bottomDrawer: Boolean
  },

  data() {
    return {
      selectionId: '',
      addIcon: mdiPlusCircle,
      editIcon: mdiCreditCardEditOutline,
      creditCardIcon: mdiCreditCardOutline,
      loading: false
    };
  },

  computed: {
    cards() {
      return this.$store.getters['cards/selectList'];
    }
  },

  watch: {
    cards(val) {
      this.selectDefaultCard();
    }
  },

  mounted() {
    this.selectDefaultCard();
    if (!this.$store.state.cards.loaded) {
      this.loading = true;
      this.$store.dispatch('cards/getAll').finally(() => {
        this.loading = false;
      });
    }
    this.$eventBus.$on(APP_EVENTS.SET_PAYMENT_METHOD, (id) => {
      var match = this.cards.find((x) => x.id === id);
      if (match) this.slectionId = id;
    });
  },

  methods: {
    selectDefaultCard() {
      var id = '';
      if (this.cards.length) {
        var match = this.cards.find(
          (x) => x.id === this.$store.state.subscription.item.defaultPaymentMethodId
        );
        if (match) id = match.id;
        if (!match) id = this.cards[0].id;
      }
      this.selectionId = id;
      this.$emit('paymentMethodSelected', id);
    },

    add() {
      this.$store.commit('drawers/SET_DATA', {
        drawerId: DRAWERS.ADD_CARD_DRAWER,
        data: {
          bottom: this.bottomDrawer,
          closeGoesBack: true
        }
      });
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.ADD_CARD_DRAWER);
    },

    edit() {
      this.$store.commit('drawers/SET_DATA', {
        drawerId: DRAWERS.UPDATE_CARD_DRAWER,
        data: {
          bottom: this.bottomDrawer,
          closeGoesBack: true
        }
      });
      this.$store.commit('cards/SET_SELECTED_CARD_ID', this.selectionId);
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.UPDATE_CARD_DRAWER);
    }
  }
};
</script>

<style scoped></style>
