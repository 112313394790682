<template>
  <v-snackbar
    v-model="snackbar"
    timeout="50000"
    :color="snackbarColor"
    top
    elevation="2"
    class="font-weight-bold"
  >
    <v-icon v-if="snackbarIcon">
      {{ snackbarIcon }}
    </v-icon>
    {{ snackbarText }}
    <template #action="{ attrs }">
      <v-btn
        icon
        v-bind="attrs"
        @click="snackbar = false"
      >
        <v-icon>{{ closeIcon }}</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mdiCheckCircleOutline, mdiAlertBoxOutline, mdiCloseCircleOutline } from '@mdi/js';
import { APP_EVENTS } from '../../utils/constants';

export default {
  name: 'Snackbar',

  data: () => ({
    snackbar: false,
    snackbarColor: 'success',
    snackbarText: '',
    snackbarIcon: '',
    closeIcon: mdiCloseCircleOutline
  }),

  mounted() {
    this.$eventBus.$emit(APP_EVENTS.SNACKBAR_MOUNTED);
  },

  methods: {
    $openSnackbar(text, color, icon) {
      this.snackbarText = text;
      this.snackbarColor = color || 'success';
      this.snackbar = true;
      this.snackbarIcon = icon;
    },
    openSnackbarSuccess(text) {
      this.$openSnackbar(text, 'success', mdiCheckCircleOutline);
    },
    openSnackbarError(text) {
      this.$openSnackbar(text, 'error', mdiAlertBoxOutline);
    }
  }
};
</script>

<style></style>
