import { ANALYTICS_EVENTS, ENUMS } from '@/utils/constants';
import { cloneDeep, isEmpty, sumBy } from 'lodash';

const state = {
  edit_campaign: {},
  count_loading: false,
  saving: false,
  mailbox_date: null,
  routes: [],
  addresses: {},
  addresses_mapping: {},
  list_purchases: [],
  previous: [],
  ideas: [],
  idea_projects: [],
  idea_type_id: null
};

const getters = {
  edit_campaign: (state) => {
    return state.edit_campaign || {};
  },
  count_loading: (state) => {
    return state.count_loading;
  },
  saving: (state) => {
    return state.saving;
  },
  is_eddm: (state, getters) => {
    return getters.edit_campaign.typeId === ENUMS.SNAILBLAST.CAMPAIGN_TYPE.EDDM;
  },
  is_upload: (state, getters) => {
    return (
      getters.edit_campaign.typeId === ENUMS.SNAILBLAST.CAMPAIGN_TYPE.UPLOAD
    );
  },
  is_list_purchase: (state, getters) => {
    return (
      getters.edit_campaign.typeId === ENUMS.SNAILBLAST.CAMPAIGN_TYPE.LIST_PURCHASE
    );
  },
  mailbox_date: (state) => {
    return state.mailbox_date;
  },
  routes: (state) => {
    return state.routes || [];
  },
  addresses: (state) => {
    return state.addresses || {};
  },
  address_list: (state, getters) => {
    return getters.addresses.addresses || [];
  },
  addresses_mapping: (state) => {
    return state.addresses_mapping || {};
  },
  addresses_mapping_ready: (state, getters) => {
    const mapping = getters.addresses_mapping;
    if (
      mapping.firstName < 0 &&
      mapping.lastName < 0 &&
      mapping.companyName < 0 &&
      !mapping.overrideCompanyName
    ) {
      return false;
    }
    if (mapping.line1 < 0 || mapping.city < 0 || mapping.state < 0 || mapping.zip < 0) {
      return false;
    }
    return true;
  },
  has_addresses_mapping: (state, getters) => {
    return !isEmpty(getters.addresses_mapping);
  },
  list_purchases: (state) => state.list_purchases || [],
  list_purchase_database_id: (state, getters) =>
    getters.list_purchases.length
      ? getters.list_purchases[0].databaseId
      : ENUMS.SNAILBLAST.LIST_PURCHASE_DATABASE.CONSUMER,
  list_purchase_filters: (state, getters) =>
    getters.list_purchases.length ? getters.list_purchases[0].filters || [] : [],
  list_purchase_address_count: (state, getters) =>
    getters.list_purchases.length ? sumBy(getters.list_purchases, 'addressCount') : 0,
  previous: (state) => {
    return state.previous || [];
  },
  address_count: (state, getters, rootState, rootGetters) => {
    if (getters.is_eddm) {
      return rootGetters['snailblast/routes/address_count'](
        getters.edit_campaign.excludeBusinesses
      );
    } else if (getters.is_upload) {
      return getters.addresses.totalCount;
    } else if (getters.is_list_purchase) {
      return getters.list_purchase_address_count;
    }
    return 0;
  },
  analytics_data: (state, getters) => {
    let type = '';
    if (getters.is_eddm) {
      type = ANALYTICS_EVENTS.MAILING_SERVICE.TYPE.EDDM;
    } else if (getters.is_upload) {
      type = ANALYTICS_EVENTS.MAILING_SERVICE.TYPE.UPLOAD;
    } else if (getters.is_list_purchase) {
      type = ANALYTICS_EVENTS.MAILING_SERVICE.TYPE.LIST_BUILDER;
    }

    const data = {};
    if (type) {
      data.type = type;
    }
    if (getters.edit_campaign && getters.edit_campaign.id) {
      data.campaignId = getters.edit_campaign.id;
    }
    return data;
  },
  ideas(state) {
    return state.ideas || [];
  },
  idea_projects(state) {
    return state.idea_projects || [];
  },
  idea_type_id: (state) => {
    return state.idea_type_id || ENUMS.SNAILBLAST.CAMPAIGN_TYPE.LIST_PURCHASE;
  }
};

const mutations = {
  SET_EDIT_CAMPAIGN(state, item) {
    state.edit_campaign = cloneDeep(item);
  },
  SET_COUNT_LOADING(state, val) {
    state.count_loading = val;
  },
  SET_SAVING(state, val) {
    state.saving = val;
  },
  SET_CAMPAIGN_ADDRESS_FILENAME(state, fileName) {
    this._vm.$set(state.edit_campaign, 'addressFileName', fileName);
  },
  SET_MAILBOX_DATE(state, date) {
    state.mailbox_date = date;
  },
  SET_CAMPAIGN_ROUTES(state, routes) {
    state.routes = routes;
  },
  SET_CAMPAIGN_ADDRESSES(state, addresses) {
    state.addresses = addresses;
    state.addresses_mapping = {};
  },
  SET_CAMPAIGN_ADDRESSES_MAPPING(state, mapping) {
    state.addresses_mapping = mapping;
  },
  SET_LIST_PURCHASE(state, items) {
    state.list_purchases = cloneDeep(items || []);
  },
  ADD_LIST_PURCHASE(state, item) {
    state.list_purchases.splice(0, 0, item);
  },
  SET_PREVIOUS_CAMPAIGNS(state, list) {
    state.previous = list;
  },
  SET_IDEAS(state, list) {
    state.ideas = list;
  },
  SET_IDEA_PROJECTS(state, list) {
    state.idea_projects = list;
  },
  DELETE_IDEA(state, id) {
    const index = state.ideas.findIndex(x => x.id === id);
    if (index >= 0) {
      state.ideas.splice(index, 1);
    }
  },
  SET_IDEA_TYPE_ID(state, val) {
    state.idea_type_id = val;
  }
};

const actions = {
  setEditCampaign({ commit }, item) {
    commit('SET_EDIT_CAMPAIGN', item);
  },

  async setWithExtraData({ commit, dispatch, state }, c) {
    commit('SET_CAMPAIGN_ADDRESSES', {});
    commit('SET_CAMPAIGN_ROUTES', []);
    commit('SET_LIST_PURCHASE', []);
    commit('SET_EDIT_CAMPAIGN', c);
    switch (c.typeId) {
      case ENUMS.SNAILBLAST.CAMPAIGN_TYPE.UPLOAD:
        await dispatch('getAddresses', c.id);
        break;
      case ENUMS.SNAILBLAST.CAMPAIGN_TYPE.LIST_PURCHASE:
        await dispatch('getListPurchases', c.id);
        break;
      case ENUMS.SNAILBLAST.CAMPAIGN_TYPE.EDDM:
        await dispatch('getRoutes', c.id);
        break;
    }
  },

  setSaving({ commit }, val) {
    commit('SET_SAVING', val);
  },

  async getMailboxDate({ commit }, typeId) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`snailblast/getMailboxDate?typeId=${typeId}`)
        .then((response) => {
          commit('SET_MAILBOX_DATE', response.data.mailboxDate);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async save({ commit }, campaign) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('snailblast/saveCampaign', campaign)
        .then((response) => {
          commit('printcart/SET_EDIT_ITEM_CAMPAIGN', response.data, { root: true });
          commit('SET_EDIT_CAMPAIGN', response.data);
          resolve(response.data.id);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async trackSaveEvent({ commit }, campaign) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('snailblast/trackSaveEvent', campaign)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async refresh({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`snailblast/getCampaign?campaignId=${id}`)
        .then((response) => {
          commit('printcart/SET_EDIT_ITEM_CAMPAIGN', response.data, { root: true });
          commit('SET_EDIT_CAMPAIGN', response.data);
          resolve(response.data.id);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async getRoutes({ commit }, campaignId) {
    if (!campaignId) {
      return;
    }
    return new Promise((resolve, reject) => {
      if (!campaignId) {
        commit('SET_CAMPAIGN_ROUTES', []);
        resolve();
        return;
      }
      this._vm.$api
        .get(`snailblast/getRoutes?campaignId=${campaignId}`)
        .then((response) => {
          commit('SET_CAMPAIGN_ROUTES', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async saveRoutes({ commit, dispatch }, request) {
    return new Promise((resolve, reject) => {
      dispatch('save', request.campaign)
        .then((campaignId) => {
          this._vm.$api
            .post(`snailblast/saveRoutes?campaignId=${campaignId}`, request.routes)
            .then((response) => {
              var routes = response.data.routes;
              commit('SET_CAMPAIGN_ROUTES', routes);
              commit('snailblast/routes/SELECT_ROUTES_TABLE', routes, { root: true });
              commit('printcart/SET_EDIT_ITEM_CAMPAIGN', response.data.campaign, { root: true });
              commit('SET_EDIT_CAMPAIGN', response.data.campaign);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async resetEddm({ commit }) {
    commit('SET_CAMPAIGN_ROUTES', {});
    commit('snailblast/routes/SELECT_ROUTES_TABLE', [], { root: true });
  },

  async uploadMailingList({ commit, dispatch }, container) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', container.file);
      this._vm.$api
        .post(`snailblast/uploadMailingList?campaignId=${container.campaignId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          commit('SET_CAMPAIGN_ADDRESSES_MAPPING', response.data);
          commit('SET_CAMPAIGN_ADDRESS_FILENAME', container.file.name);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async applyMapping({ commit, dispatch }, container) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', container.file);
      this._vm.$api
        .post(`snailblast/applyMapping?campaignId=${container.campaignId}`, container.mapping)
        .then((response) => {
          commit('SET_CAMPAIGN_ADDRESSES', response.data.addresses);
          commit('SET_EDIT_CAMPAIGN', response.data.campaign);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async getAddresses({ commit }, id) {
    if (!id) {
      return;
    }
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`snailblast/getAddresses?campaignId=${id}`)
        .then((response) => {
          commit('SET_CAMPAIGN_ADDRESSES', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async copyAddresses({ commit, state, dispatch }, oldCampaignId) {
    return new Promise((resolve, reject) => {
      dispatch('save', state.edit_campaign)
        .then((campaignId) => {
          this._vm.$api
            .put(
              `snailblast/copyAddresses?newCampaignId=${campaignId}&oldCampaignId=${oldCampaignId}`
            )
            .then((response) => {
              commit('SET_CAMPAIGN_ADDRESSES', response.data.addresses);
              commit('SET_EDIT_CAMPAIGN', response.data.campaign);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async resetUpload({ commit }) {
    commit('SET_CAMPAIGN_ADDRESSES', {});
    commit('SET_CAMPAIGN_ADDRESSES_MAPPING', {});
    commit('SET_CAMPAIGN_ADDRESS_FILENAME', '');
  },

  async deleteDuplicates({ commit }, campaignId) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .delete(`snailblast/deleteDuplicates?campaignId=${campaignId}`)
        .then((response) => {
          commit('SET_CAMPAIGN_ADDRESSES', response.data.addresses);
          commit('SET_EDIT_CAMPAIGN', response.data.campaign);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async deleteErrors({ commit }, campaignId) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .delete(`snailblast/deleteErrors?campaignId=${campaignId}`)
        .then((response) => {
          commit('SET_CAMPAIGN_ADDRESSES', response.data.addresses);
          commit('SET_EDIT_CAMPAIGN', response.data.campaign);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async saveAddress({ commit, state }, address) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post(`snailblast/saveAddress?campaignId=${state.edit_campaign.id}`, address)
        .then((response) => {
          commit('SET_CAMPAIGN_ADDRESSES', response.data);
          resolve({ address: {} });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async deleteAddress({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .delete(`snailblast/deleteAddress?campaignId=${state.edit_campaign.id}&id=${id}`)
        .then((response) => {
          commit('SET_CAMPAIGN_ADDRESSES', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async getListPurchases({ commit }, id) {
    if (!id) {
      return;
    }
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`snailblast/getListPurchases?campaignId=${id}`)
        .then((response) => {
          commit('SET_LIST_PURCHASE', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async saveListPurchases({ commit, dispatch }, request) {
    return new Promise((resolve, reject) => {
      dispatch('save', request.campaign)
        .then((campaignId) => {
          this._vm.$api
            .post(`snailblast/saveListPurchases?campaignId=${campaignId}`, request.list_purchases)
            .then((response) => {
              commit('SET_LIST_PURCHASE', response.data.list_purchases);

              commit('printcart/SET_EDIT_ITEM_CAMPAIGN', response.data.campaign, { root: true });
              commit('SET_EDIT_CAMPAIGN', response.data.campaign);
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getDefaultListPurchase({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`snailblast/getDefaultListPurchase?projectId=${rootGetters['printcart/project'].id || 0}`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setListPurchases({ commit }, item) {
    commit('SET_LIST_PURCHASE', item);
  },
  addListPurchase({ commit }, item) {
    commit('ADD_LIST_PURCHASE', item);
  },
  async getListPurchaseCount({ commit }, listPurchase) {
    commit('SET_COUNT_LOADING', true);
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('snailblast/getListPurchaseCount', listPurchase)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit('SET_COUNT_LOADING', false);
        });
    });
  },

  async getPreviousCampaigns({ commit }, typeId) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(
          `snailblast/getPreviousCampaigns?typeId=${typeId}`
        )
        .then((response) => {
          commit('SET_PREVIOUS_CAMPAIGNS', response.data);
          resolve(response.data.length);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getIdeas({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('snailblast/getIdeas')
        .then((response) => {
          commit('SET_IDEAS', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getIdeaUrl({ commit }, request) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`snailblast/getIdeaUrl?id=${request.id}&projectId=${request.projectId}`)
        .then((response) => {
          resolve(response.data.url);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getIdeaProjects({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`snailblast/getIdeaProjects?id=${id}`)
        .then((response) => {
          commit('SET_IDEA_PROJECTS', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async saveIdea({ commit }, idea) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('snailblast/saveIdea', idea)
        .then((response) => {
          commit('SET_IDEAS', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getIdeaFilters({ commit }, id) {
    return new Promise((resolve, reject) => {
      if (!id) {
        commit('SET_LIST_PURCHASE', []);
        resolve();
      } else {
        this._vm.$api
          .get('snailblast/getIdeaFilters', id)
          .then((response) => {
            commit('SET_LIST_PURCHASE', [response.data]);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
  async saveIdeaFilters({ commit }, listPurchase) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('snailblast/saveIdeaFilters', listPurchase)
        .then((response) => {
          commit('SET_LIST_PURCHASE', [response.data]);
          resolve(response.data.id);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async deleteIdea({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .delete(`snailblast/deleteIdea?id=${id}`)
        .then((response) => {
          commit('DELETE_IDEA', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  setIdeaTypeId({ commit }, val) {
    commit('SET_IDEA_TYPE_ID', val);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
