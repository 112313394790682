<template>
  <div :style="{ height: `${height}`, position: 'relative' }">
    <div
      :id="id"
      class="h-full w-full"
    />
    <SnailblastSearch
      v-if="!readonly"
      style="left: 10px; width: calc(100% - 20px); top: 10px; position: absolute"
      @search="addRadius"
    />
    <v-btn
      ref="addRadius"
      color="primary"
      :class="{ 'd-none': !marker_new }"
      @click="addRadius(null)"
    >
      Target area
    </v-btn>
    <div
      class="d-flex"
      style="z-index: 10000; right: 10px; bottom: 10px; position: absolute"
    >
      <MapZoom @zoom="changeZoom" />
      <MapStyle @select="changeStyle" />
      <MapExpand
        v-if="!readonly && !is_mobile"
        :expanded="expanded"
        @expand="$emit('expand')"
      />
    </div>
  </div>
</template>

<script>
import SnailblastSearch from '@/components/print/checkout/snailblast/Search';
import MapExpand from '@/components/print/checkout/snailblast/map/Expand';
import MapStyle from '@/components/print/checkout/snailblast/map/Style';
import MapZoom from '@/components/print/checkout/snailblast/map/Zoom';
import { ANALYTICS_EVENTS, COLORS, ENUMS } from '@/utils/constants';
import snailblast from '@/utils/mixins/snailblast';
import { mdiArrowCollapseAll, mdiArrowExpandAll, mdiMinus, mdiPlus } from '@mdi/js';
import circle from '@turf/circle';
import mapboxgl from 'mapbox-gl';
import turf from 'turf';
import { mapGetters } from 'vuex';
export default {
  components: { SnailblastSearch, MapStyle, MapExpand, MapZoom },
  mixins: [snailblast],
  props: {
    height: { type: String, default: '100%' },
    readonly: { type: Boolean, default: false },
    id: { type: String, default: 'map' },
    selected: { type: Boolean, default: false },
    expanded: { type: Boolean, default: false }
  },
  data() {
    return {
      expandIcon: mdiArrowExpandAll,
      collapseIcon: mdiArrowCollapseAll,
      plusIcon: mdiPlus,
      minusIcon: mdiMinus,
      map: null,
      marker_new: null,
      radius_source: 'radius',
      radius_layer: 'radius',
      circle_source: 'circle',
      circle_layer: 'circle',
      center: [-96, 37.8],
      zoom: 3,
      style: ENUMS.SNAILBLAST.MAP_STYLES.STREETS,
      map_loaded: false,
      currentSelection: null
    };
  },
  computed: {
    radiuses() {
      const data = this.list_purchases;
      if (data.length) {
        return data.map((x) => x.radius).filter((x) => x && x.shape);
      }
      return [];
    },
    circle_data() {
      const circles = [];
      if (this.radiuses && this.radiuses.length) {
        for (const radius of this.radiuses) {
          const _center = turf.point(radius.shape.coordinates);
          const _radius = radius.radius;
          const _options = {
            steps: 80,
            units: 'miles'
          };

          circles.push(circle(_center, _radius, _options));
        }
      }
      return turf.featureCollection(circles);
    },
    radius_data() {
      const features = [];
      if (this.radiuses && this.radiuses.length) {
        for (const radius of this.radiuses) {
          if (radius && radius.shape) {
            features.push({
              type: 'Feature',
              geometry: radius.shape,
              properties: { text: radius.text },
              id: 1
            });
          }
        }
      }
      return {
        type: 'FeatureCollection',
        features
      };
    },
    is_mobile() {
      console.log('is_mobile', this.$vuetify.breakpoint.mobile);
      return this.$vuetify.breakpoint.mobile;
    },
    ...mapGetters({
      list_purchases: 'snailblast/campaign/list_purchases',
      campaign: 'snailblast/campaign/edit_campaign',
      analytics_data: 'snailblast/campaign/analytics_data'
    })
  },
  watch: {
    list_purchases: {
      deep: true,
      handler(val, oldVal) {
        if (this.map_loaded) {
          this.setSource();
        }
      }
    }
  },
  async mounted() {
    if (!this.list_purchases.length) {
      await this.setListPurchases([await this.getDefaultListPurchase()], true);
    }
    this.createMap();
    const self = this;
    document.addEventListener('keyup', function (evt) {
      if (evt.keyCode === 27) {
        self.removeSearchMarker();
      }
    });
  },
  methods: {
    changeStyle(val) {
      this.style = val;
      this.zoom = this.map.getZoom();
      this.center = this.map.getCenter();
      this.createMap();
    },
    createMap() {
      if (this.map) {
        this.map = null;
      }
      this.map = new mapboxgl.Map({
        accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
        container: this.id, // <div id="map"></div>
        style: this.style,
        center: this.center, // starting position
        zoom: this.zoom, // starting zoom
        attributionControl: false
      });
      this.map.on('load', () => {
        this.map_loaded = true;
        this.setSource();
        this.bindEvents();
        this.map.resize();
      });
    },
    bindEvents() {
      this.map.off('click');
      this.map.on('click', (e) => {
        this.setSearchMarker(e.lngLat);
      });
    },
    setSource() {
      this.removeSearchMarker();
      this.setRadiusSource();

      this.zoomToBounds();
    },
    setRadiusSource() {
      const source = this.map.getSource(this.radius_source);
      if (source) {
        source.setData(this.radius_data);
      } else {
        this.map.addSource(this.radius_source, {
          type: 'geojson',
          data: this.radius_data
        });

        this.map.addLayer({
          id: this.radius_layer,
          type: 'circle',
          layout: {},
          source: this.radius_source,
          paint: {
            'circle-radius': 5,
            'circle-color': COLORS.GREEN,
            'circle-stroke-color': COLORS.GREEN,
            'circle-stroke-width': 1
          }
        });
      }

      // Create a popup, but don't add it to the map yet.
      const markerHeight = 50;
      const markerRadius = 10;
      const linearOffset = 25;
      const popupOffsets = {
        top: [0, 0],
        'top-left': [0, 0],
        'top-right': [0, 0],
        bottom: [0, markerHeight],
        'bottom-left': [linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
        'bottom-right': [-linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
        left: [markerRadius, (markerHeight - markerRadius) * -1],
        right: [-markerRadius, (markerHeight - markerRadius) * -1]
      };
      this.popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
        offset: popupOffsets
      });

      const circleSource = this.map.getSource(this.circle_source);
      if (circleSource) {
        circleSource.setData(this.circle_data);
      } else {
        this.map.addSource(this.circle_source, {
          type: 'geojson',
          data: this.circle_data
        });

        this.map.addLayer({
          id: this.circle_layer,
          type: 'fill',
          source: this.circle_source,
          paint: {
            'fill-color': COLORS.GREEN,
            'fill-opacity': 0.2
          }
        });
      }
    },
    async setSearchMarker(lngLat) {
      if (this.readonly) {
        return;
      }
      if (!this.marker_new) {
        this.marker_new = new mapboxgl.Marker({ color: this.$vuetify.theme.themes.light.accent })
          .setLngLat(lngLat)
          .addTo(this.map);
      } else {
        this.marker_new.setLngLat(lngLat);
      }
      this.popup.setLngLat(lngLat).setDOMContent(this.$refs.addRadius.$el).addTo(this.map);
      this.map.panTo(lngLat);
      this.currentSelection = await this.getMapboxPlace(lngLat.lat, lngLat.lng);
    },
    removeSearchMarker(skip) {
      if (this.marker_new) {
        this.marker_new.remove();
        this.marker_new = null;
      }
      if (this.popup) {
        this.popup.remove();
      }
      this.currentSelection = null;
    },
    zoomToBounds() {
      let features = [];
      if (this.circle_data && this.circle_data.features && this.circle_data.features.length) {
        features = features.concat(
          this.circle_data.features.map((x) => turf.polygon(x.geometry.coordinates))
        );
      }

      if (!features.length) {
        this.resetMap();
        return;
      }
      const bounds = turf.bbox(turf.featureCollection(features));

      this.map.fitBounds(bounds, { padding: 50, maxZoom: 16 });
    },
    resetMap() {
      this.map.easeTo({ center: this.center, zoom: this.zoom });
    },
    addRadius(val) {
      this.$mixpanel.trackEvent(
        ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
        ANALYTICS_EVENTS.MAILING_SERVICE.ACTIONS.LOCATION_SET,
        this.analytics_data
      );
      const radius = Object.assign(
        { radius: val ? val.radius : 5 },
        this.convertMapboxToRadiusLocation(val ? val.mapbox : this.currentSelection[0])
      );
      this.addListPurchase({ radius });
    },
    changeZoom(zoomIn) {
      if (zoomIn) {
        this.map.zoomIn();
      } else {
        this.map.zoomOut();
      }
    },
    zoomTo(radius) {
      if (!radius) {
        return;
      }
      const _center = turf.point(radius.shape.coordinates);
      const _radius = radius.radius;
      const _options = {
        steps: 80,
        units: 'miles'
      };

      const bounds = turf.bbox(turf.featureCollection([circle(_center, _radius, _options)]));

      this.map.fitBounds(bounds, { padding: 50, maxZoom: 16 });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .mapboxgl-popup-tip {
    display: none;
  }
  .mapboxgl-popup-content {
    background-color: transparent;
    padding: 0 !important;
    font-family: $body-font-family !important;
  }
  .mapboxgl-ctrl-logo {
    display: none !important;
  }
}

@import 'https://api.mapbox.com/mapbox-gl-js/v2.5.1/mapbox-gl.css';
</style>
