import { isEmpty } from 'lodash';

const state = {
  all: {},
  list_purchase_field_items: []
};

const getters = {
  all: (state) => {
    return state.all || {};
  },
  list_purchase_databases: (state, getters) => {
    return getters.all.list_purchase_databases;
  },
  list_purchase_fields: (state) => (databaseId) => {
    return (state.all.list_purchase_fields || []).filter((x) => x.databaseIds.includes(databaseId));
  },
  list_purchase_field_items: (state) => (fieldId) => {
    return state.list_purchase_field_items[fieldId] || [];
  },
  list_purchase_database: (state) => (id) => {
    return (state.all.list_purchase_databases || [])
      .filter((x) => x.id === id)
      .map((x) => x.name)[0];
  },
  list_purchase_field: (state) => (id) => {
    return (state.all.list_purchase_fields || []).find((x) => x.id === id) || {};
  },
  campaign_types: (state, getters) => {
    return getters.all.campaign_types;
  },
  campaign_type: (state) => (id) => {
    return (state.all.campaign_types || []).find((x) => x.id === id) || {};
  }
};

const mutations = {
  SET_LOOKUPS(state, lookups) {
    state.all = lookups;
  },
  SET_LIST_PURCHASE_FIELD_ITEMS(state, item) {
    const index = state.all.list_purchase_fields.findIndex((x) => x.id === item.id);
    if (index >= 0) {
      this._vm.$set(state.all.list_purchase_fields[index], 'selectItems', item.selectItems);
    }
  }
};

const actions = {
  async get({ commit, state }) {
    if (!isEmpty(state.all)) {
      return;
    }
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('snailblast/getLookups')
        .then((response) => {
          commit('SET_LOOKUPS', response.data);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async getListPurchaseFieldSelectItems({ commit }, id) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get(`snailblast/getListPurchaseFieldItems?id=${id}`)
        .then((response) => {
          commit('SET_LIST_PURCHASE_FIELD_ITEMS', {
            id,
            selectItems: response.data
          });
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
