<template>
  <div class="bordered border-light rounded mt-4 pa-4">
    <div class="font-weight-bold">
      <v-icon
        class="mr-1"
        small
      >
        {{ mailIcon }} </v-icon
      >Mailing guide check
    </div>
    <div class="subtitle-2 mt-3 mb-4">
      <ul class="ml-n2">
        <li>We found your design needs new mailing guides.</li>
        <li>Let's add and review these changes together.</li>
        <li>We'll create a copy of your design. No work is lost!</li>
      </ul>
    </div>
    <div class="my-3">
      <v-btn
        block
        outlined
        large
        :loading="loading"
        @click="addGuides"
      >
        Add correct mailing guides
      </v-btn>
    </div>
    <div v-if="!campaign_idea">
      <v-btn
        block
        text
        color="brand"
        @click="skip"
      >
        Skip for now and just view pricing
      </v-btn>
    </div>
  </div>
</template>

<script>
import { ANALYTICS_EVENTS, APP_EVENTS, DRAWERS } from '@/utils/constants';
import snailblast from '@/utils/mixins/snailblast';
import { mdiSend } from '@mdi/js';
import { mapGetters } from 'vuex';
export default {
  name: 'EditItemMailingGuideCheck',
  mixins: [snailblast],
  props: {
    mailingOption: { type: Object, default: () => {} },
    sizeError: { type: Boolean, default: false },
    guideError: { type: Boolean, default: false }
  },
  data: () => ({
    mailIcon: mdiSend,
    loading: false
  }),
  computed: {
    edit_item: {
      get() {
        return this.$store.getters['printcart/edit_item'];
      },
      set(val) {
        this.$store.dispatch('printcart/setEditItem', val);
      }
    },
    guideToUse() {
      return this.getGuideType(this.mailingOption.campaignTypeId);
    },
    ...mapGetters({
      campaign_idea: 'printcart/campaign_idea',
      editor_url: 'printcart/editor_url',
      analytics_data: 'snailblast/campaign/analytics_data'
    })
  },
  mounted() {
    let error = 'sizeAndGuide';
    if (!this.sizeError) {
      error = 'guide';
    } else if (!this.guideError) {
      error = 'size';
    }
    this.$mixpanel.trackEvent(
      ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
      null,
      Object.assign(this.analytics_data, { error })
    );
    this.loading = true;
    this.$store
      .dispatch('printcart/getEditorUrl', {
        projectId: this.edit_item.project.id,
        guideType: this.guideToUse,
        coreProductId: this.mailingOption.defaultCoreProductId
      })
      .catch((error) => {
        this.logError(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    skip() {
      this.$store
        .dispatch('printcart/setEditItem', {
          coreProductId: this.mailingOption.defaultCoreProductId,
          project: {}
        })
        .then(() => {
          this.$eventBus.$emit(APP_EVENTS.EDIT_ITEM_LOAD_DATA, {});
        });
    },
    addGuides() {
      this.$mixpanel.trackEvent(
        ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
        ANALYTICS_EVENTS.MAILING_SERVICE.ACTIONS.FIX_FREEDOM,
        this.analytics_data
      );
      this.$store.commit('drawers/SET_DATA', {
        drawerId: DRAWERS.EDITOR_DRAWER,
        data: { editorUrl: this.editor_url }
      });
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.EDITOR_DRAWER);
    }
  }
};
</script>
