<template>
  <v-progress-linear
    v-if="loading"
    :indeterminate="loading"
    class="absolute"
    style="z-index: 9999"
    :height="5"
    color="brand"
  />
</template>

<script>
export default {
  name: 'PageLoadingProgress',

  data() {
    return {};
  },

  computed: {
    loading() {
      return this.$store.state.config.pageLoading;
    }
  }
};
</script>
