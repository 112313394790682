<template>
  <v-sheet class="pa-4">
    <div>
      <div class="text-h6">Thank you 🎉</div>
    </div>
    <div>
      Thanks for your order! Our team will take good care of your order as it moves through our
      facility and into the mail.
    </div>
    <v-divider class="my-6" />
    <div>
      <div class="text-h6">View Your Dashboard</div>
    </div>
    <div>
      Your dashboard is where you manage your projects, orders, profile and much more. We've placed
      a copy of your receipt there for you as well.
    </div>
    <v-btn
      x-large
      color="primary"
      elevation="0"
      block
      class="mt-5"
      @click="viewReceipt"
    >
      <v-icon
        size="28"
        class="mr-3"
      >
        {{ packageIcon }}
      </v-icon>
      View Receipt in My Dashboard
    </v-btn>
    <div class="body-2 mt-3">*** Order tracking will be available once this order has shipped</div>
  </v-sheet>
</template>

<script>
import { mdiPackageVariantClosed } from '@mdi/js';
import { ROUTES } from '../../../utils/constants';

export default {
  name: 'PrintCheckoutConfirmation',

  props: {
    iframed: Boolean,
    orderId: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      packageIcon: mdiPackageVariantClosed
    };
  },

  computed: {},

  methods: {
    viewReceipt() {
      if (this.iframed) {
        window.parent.postMessage(
          {
            type: 'go-to-url',
            data: { url: `${process.env.VUE_APP_DASHBOARD_URL}orders`, newWindow: false }
          },
          '*'
        );
      } else {
        window.location.href = ROUTES.ORDER_DETAIL.PATH + '/' + this.orderId;
      }
    }
  }
};
</script>
