<template>
  <v-btn
    v-if="render"
    icon
    small
    class="mr-3"
    color="black"
  >
    <v-icon
      size="18"
      class="thick"
    >
      {{ bellIcon }}
    </v-icon>
  </v-btn>
</template>

<script>
import { mdiBellOutline } from '@mdi/js';

export default {
  name: 'NotificationButton',

  data() {
    return {
      render: false,
      bellIcon: mdiBellOutline
    };
  },

  computed: {},

  methods: {}
};
</script>

<style></style>
