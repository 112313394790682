<template>
  <v-sheet class="rounded-xl bordered border-light overflow-hidden">
    <v-carousel hide-delimiters>
      <template #prev="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          light
          class="white"
          v-on="on"
        >
          <v-icon
            color="black"
            class="thick"
          >
            {{ iconArrowLeft }}
          </v-icon>
        </v-btn>
      </template>
      <template #next="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          light
          class="white"
          v-on="on"
        >
          <v-icon
            color="black"
            class="thick"
          >
            {{ iconArrowRight }}
          </v-icon>
        </v-btn>
      </template>
      <v-carousel-item
        v-for="(image, i) in imagesToRender"
        :key="i"
      >
        <v-sheet
          light
          class="pa-5 h-full d-flex align-center justify-center"
        >
          <v-img
            :src="image.url"
            :lazy-src="image.thumbUrl"
            max-width="90%"
            max-height="100%"
            contain
          />
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </v-sheet>
</template>

<script>
import { mdiArrowLeft, mdiArrowRight } from '@mdi/js';

export default {
  name: 'ProjectPreviewCarousel',

  props: {
    images: {
      type: Array,
      default: () => []
    },
    id: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      iconArrowLeft: mdiArrowLeft,
      iconArrowRight: mdiArrowRight,
      project: {}
    };
  },

  computed: {
    imagesToRender() {
      if (this.images.length) return this.images;
      if (this.project) return this.project.images;
      return [];
    }
  },

  mounted() {
    if (this.id) {
      this.$api.get(`project/detail/${this.id}`).then((response) => {
        this.project = response.data;
      });
    }
  },

  methods: {}
};
</script>

<style scoped></style>
