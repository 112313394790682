import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=2fe08f23&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "./Header.vue?vue&type=style&index=0&id=2fe08f23&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fe08f23",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAppBar,VAppBarNavIcon,VBreadcrumbs,VBreadcrumbsItem,VContainer,VSpacer})
