<template>
  <v-sheet>
    <div>
      <LottieAnimation
        path="//res.cloudinary.com/mycreativeshop/raw/upload/v1700251720/public/editor/subscribe-success-lottie.json"
      />
    </div>
    <v-divider class="my-6" />
    <div class="px-5 mb-5">
      <div class="d-flex justify-space-between align-center">
        <div class="font-weight-bold title">Order Confirmation 🎉</div>
        <v-btn
          color="primary"
          text
          @click="receipt"
        >
          view receipt >
        </v-btn>
      </div>
      <div class="my-6">
        You've just unlocked unlimited access to everything MyCreativeShop has to offer. We're
        excited to have you here with us!
      </div>
      <IconList
        :items="planFeatures"
        classes="mb-5"
        iconColor="primary"
      />
      <div>Have questions? support@mycreativeshop.com</div>
      <div class="mt-5">Cheers,<br />MyCreativeShop Team</div>
      <v-btn
        x-large
        color="primary"
        elevation="0"
        block
        class="mt-5"
        @click="design"
      >
        Back To Design >
      </v-btn>
      <v-btn
        x-large
        color="#E5E5E5"
        class="mt-2"
        elevation="0"
        block
        @click="receipt"
      >
        Your Dashboard
      </v-btn>
    </div>
  </v-sheet>
</template>

<script>
import { mdiPackageVariantClosed } from '@mdi/js';
import IconList from '../utilities/IconList';
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue';

export default {
  name: 'SubscriptionCheckoutConfirmation',

  components: {
    IconList,
    LottieAnimation
  },

  props: {
    planFeatures: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      packageIcon: mdiPackageVariantClosed
    };
  },

  computed: {},

  methods: {
    receipt() {
      window.parent.postMessage(
        {
          type: 'go-to-url',
          data: { url: `${process.env.VUE_APP_DASHBOARD_URL}orders`, newWindow: true }
        },
        '*'
      );
    },

    design() {
      window.parent.postMessage({ type: 'close-iframed-drawers' }, '*');
    }
  }
};
</script>
