import tinycolor from 'tinycolor2';

export default {
  methods: {
    getColorWithOpacity(color, opacity) {
      const newColor = tinycolor(color);
      newColor.setAlpha(opacity);
      return newColor.toRgbString();
    }
  }
};
