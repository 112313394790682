<template>
  <Drawer
    :id="drawerId"
    :title="title"
    :titleIcon="titleIcon"
    titleIconColor="#FEBF07"
  >
    <template v-if="selectedPlan && !showConfirmation">
      <div :class="{ 'd-flex h-full': showLeftCol }">
        <v-sheet
          v-if="showLeftCol"
          class="h-100 flex-shrink-0 body-2 px-4 pt-5 pb-4 border-r border-light bg-light"
          style="width: 350px"
        >
          <div class="font-weight-bold body-1 mb-4">Here's what you get:</div>
          <IconList
            :items="planFeatures"
            classes="mb-5"
            iconColor="primary"
          />
          <v-divider class="my-3" />

          <div class="d-flex align-center justify-space-between mt-4 mb-2">
            <span class="font-weight-bold body-1">Over 5,000+ 5-star reviews</span>
            <v-rating
              dense
              readonly
              size="20"
              :color="$vuetify.theme.themes.light.reviewStar"
              :value="5"
            />
          </div>

          <v-carousel
            cycle
            height="120"
            :show-arrows-on-hover="true"
            hide-delimiters
          >
            <v-carousel-item
              v-for="(review, index) in reviews"
              :key="index"
            >
              <v-sheet class="rounded-lg bg-gradient px-2 py-3 d-flex bordered border-light">
                <v-icon
                  size="30"
                  class="mr-2"
                >
                  {{ quoteIcon }}
                </v-icon>
                <div>
                  <div class="font-italic body-1">
                    {{ review.comment }}
                  </div>
                  <div class="text-right mt-2 caption">
                    {{ review.name }}
                  </div>
                </div>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
          <v-divider class="mb-3" />
          <div class="font-weight-bold body-1 d-flex align-center justify-space-between">
            Have Questions?
            <div
              class="hover-pointer"
              @click="help"
            >
              <span class="primary--text ml-2">Just ask us!</span>
              <v-btn
                icon
                small
                color="primary"
                @click.stop="help"
              >
                <v-icon>
                  {{ chatIcon }}
                </v-icon>
              </v-btn>
            </div>
          </div>

          <v-sheet
            class="bg-gradient bordered border-light rounded-lg pa-3 mt-3 d-flex body-1 align-center"
          >
            <v-icon
              size="30"
              class="mr-2 rotate-45"
            >
              {{ handshakeIcon }}
            </v-icon>
            <span
              >Over A Decade of Service to
              <span class="font-weight-bold">Small Businesses</span></span
            >
          </v-sheet>
          <v-sheet
            v-if="showUSA"
            class="bg-gradient bordered border-light rounded-lg pa-3 mt-3 d-flex body-1 align-center"
          >
            <img
              src="https://res.cloudinary.com/mycreativeshop/image/upload/f_auto/v1701186084/public/usa-heart"
              style="height: 26px"
              class="mr-2"
            />
            <span
              >Proudly built, owned & operated in the heart of the
              <span class="font-weight-bold">USA</span></span
            >
          </v-sheet>
        </v-sheet>
        <div>
          <div>
            <v-sheet
              class="bordered border-light rounded-lg ma-5 overflow-hidden bg-gradient"
              :class="{ 'pb-4': !doFirstMonthDiscount }"
            >
              <div class="pt-4 px-6">
                <div class="d-flex justify-space-between align-center">
                  <span class="font-weight-bold title">Get Unlimited Access</span>
                  <v-btn
                    v-if="!showLeftCol"
                    small
                    rounded
                    elevation="0"
                    color="white"
                    @click="showPlanDetail = !showPlanDetail"
                  >
                    What's Included?
                    <v-icon
                      x-small
                      class="ml-1"
                    >
                      <template v-if="showPlanDetail">
                        {{ chevronUp }}
                      </template>
                      <template v-else>
                        {{ chevronDown }}
                      </template>
                    </v-icon>
                  </v-btn>
                </div>
                <div class="mt-3 mb-4">
                  <div>Unlimited Access Subscription: {{ selectedPlan.costFormatted }}</div>
                  <div>{{ selectedPlan.billingPeriod }}, cancel anytime</div>
                </div>
                <v-expand-transition v-if="!showLeftCol">
                  <div
                    v-show="showPlanDetail"
                    class="my-5"
                  >
                    <div class="font-weight-bold mb-4">Here's what you get:</div>
                    <IconList
                      :items="planFeatures"
                      classes="mb-5"
                      iconColor="primary"
                    />
                    <v-carousel
                      cycle
                      height="120"
                      :show-arrows-on-hover="true"
                      hide-delimiters
                    >
                      <v-carousel-item
                        v-for="(review, index) in reviews"
                        :key="index"
                      >
                        <v-sheet class="rounded-lg px-4 py-3 d-flex bordered border-light">
                          <v-icon
                            size="30"
                            class="mr-2"
                          >
                            {{ quoteIcon }}
                          </v-icon>
                          <div>
                            <div class="font-italic body-1">
                              {{ review.comment }}
                            </div>
                            <div class="text-right mt-2 caption">
                              {{ review.name }}
                            </div>
                          </div>
                        </v-sheet>
                      </v-carousel-item>
                    </v-carousel>
                  </div>
                </v-expand-transition>
              </div>
              <div v-if="doFirstMonthDiscount">
                <v-divider />
                <div
                  class="relative py-4"
                  :style="{ 'background-color': $vuetify.theme.themes.light.tan }"
                >
                  <img
                    src="https://res.cloudinary.com/mycreativeshop/image/upload/v1700165963/public/editor/icon-curvy-arrow"
                    style="top: -15px; left: 10px"
                    class="absolute"
                  />
                  <div class="body-1 mx-10 font-weight-bold">
                    <span class="primary--text font-weight-bold ml-4">Special Offer</span
                    >&nbsp;-&nbsp; {{ selectedPlan.firstMonthDiscountAmountFormatted }} Discount
                    Applied!
                    <!-- First month only {{ selectedPlan.firstMonthCostFormatted }} -->
                  </div>
                </div>
              </div>
            </v-sheet>
          </div>
          <v-divider
            v-if="!showLeftCol"
            class="mb-3"
          />
          <div class="px-5">
            <div
              v-if="upgradeInvoice"
              class="my-3"
            >
              <PaymentSelector
                v-if="upgradeInvoice"
                @paymentMethodSelected="(id) => (paymentMethodId = id)"
              />
            </div>
            <PaymentEntryForm
              v-if="!upgradeInvoice"
              mode="subscription"
              :amount="totalInSubunit"
              showCards
              :stripePlanId="selectedPlan.stripePlanId"
              :promoCode="doFirstMonthDiscount ? selectedPlan.firstMonthPromoCode : ''"
              :currency="selectedPlan.currency.code"
              portalButtonTo="buttonPortal"
              buttonText="Place Order"
              class="mt-5 mb-5"
              @success="onSuccess"
              @accountCreated="onAccountCreated($event)"
            />

            <template v-else>
              <Portal to="buttonPortal">
                <div class="my-3">
                  <div class="font-weight-medium body-2">You are getting a credit!</div>
                  <div class="caption">
                    The base price of this plan is <span v-text="selectedPlan.costFormatted" /> per
                    <span v-text="selectedPlan.interval" />. You are receiving a credit of
                    <span v-text="upgradeInvoice.creditFormatted" /> for the unused days in your
                    current plan. You will save
                    <span v-text="upgradeInvoice.savingsFormatted" /> over the course of this plan
                    as compared to your current plan. You will not be charged again until
                    <span
                      class="font-weight-bold"
                      v-text="upgradeInvoice.periodEnd"
                    />, at which point your subscription will renew. This plan can be canceled at
                    any time.
                  </div>
                </div>
                <v-btn
                  color="primary"
                  block
                  x-large
                  class="mt-5"
                  rounded
                  :loading="changingPlan"
                  @click="changePlan"
                >
                  Change Plan
                </v-btn>
                <v-alert
                  v-show="error"
                  color="error"
                  outlined
                  class="mt-3"
                  v-text="error"
                />
              </Portal>
            </template>
          </div>
        </div>
      </div>
    </template>
    <template
      v-if="selectedPlan && !showConfirmation"
      #append
    >
      <v-divider mb-3 />
      <div class="pa-5">
        <div class="d-flex justify-space-between font-weight-bold title mb-2">
          <div>Total</div>
          <div>{{ totalFormatted }}</div>
        </div>

        <MultiCurrencyToggle />

        <PortalTarget name="buttonPortal" />
        <div
          v-if="!upgradeInvoice"
          class="mt-3 text-center caption font-weight-medium"
        >
          <!-- This monthly subscription auto-renews at {{ selectedPlan.monthlyCostFormatted }} after the first month. Cancel anytime from your account. -->
          Payments are 128-bit SSL encrypted meaning they're 100% safe with us.
        </div>
      </div>
    </template>
    <Confirmation
      v-if="showConfirmation"
      :planFeatures="planFeatures"
      @close="onClose"
    />
  </Drawer>
</template>

<script>
import { ANALYTICS_EVENTS, APP_EVENTS, DRAWERS, ROUTES } from '../../utils/constants';
import {
  mdiCircleOutline,
  mdiCircleSlice8,
  mdiChevronDown,
  mdiChevronUp,
  mdiCheckCircle,
  mdiCrownOutline,
  mdiHandshakeOutline,
  mdiCommentQuoteOutline,
  mdiChatQuestionOutline
} from '@mdi/js';
import Drawer from '../../components/layout/Drawer';
import PaymentEntryForm from '../payments/PaymentEntryForm';
import PaymentSelector from '../payments/PaymentSelector';
import MultiCurrencyToggle from '../subscription/MultiCurrencyToggle';
import Confirmation from '../subscription/Confirmation';
import IconList from '../utilities/IconList';
import { mapGetters } from 'vuex';

export default {
  name: 'SubscriptionCheckoutDrawer',

  components: {
    Drawer,
    PaymentEntryForm,
    PaymentSelector,
    MultiCurrencyToggle,
    Confirmation,
    IconList
  },

  props: {
    iframed: Boolean
  },

  data() {
    return {
      paymentMethodId: 0,
      changingPlan: false,
      circleIcon: mdiCircleOutline,
      circleFilledIcon: mdiCircleSlice8,
      chevronDown: mdiChevronDown,
      chevronUp: mdiChevronUp,
      crownIcon: mdiCrownOutline,
      handshakeIcon: mdiHandshakeOutline,
      quoteIcon: mdiCommentQuoteOutline,
      chatIcon: mdiChatQuestionOutline,
      drawerId: DRAWERS.SUBSCRIPTION_CHECKOUT,
      showConfirmation: false,
      showPlanDetail: false,
      error: '',
      planFeatures: [
        {
          text: '<div class="font-weight-bold mt-n1">Unlimited Designs & Downloads</div><div>with 20,000+ premium templates</div>',
          icon: mdiCheckCircle
        },
        {
          text: '<div class="font-weight-bold mt-n1">Print with us or anywhere you want</div><div> for complete creative freedom.</div>',
          icon: mdiCheckCircle
        },
        {
          text: '<div class="font-weight-bold mt-n1">Design with speed & style using</div><div> resize wizard, background remover, writing wizard, custom fonts & more!</div>',
          icon: mdiCheckCircle
        }
      ],
      reviews: [
        {
          comment: 'THIS is exactly what I was looking for! Love it. Easy to use.',
          name: 'Liz V, Deja Blu Dance Band'
        },
        {
          comment: 'Better than any other program I used. Bye Vista Print!',
          name: 'Quinton Lynch, CanineClassics.com'
        },
        {
          comment: 'Best graphic software ever simple and easy to use',
          name: 'Juan Obama, Plan Your Trip'
        },
        {
          comment: 'This has made my life so much easier!',
          name: 'Dawn Milburn, Quail Park'
        },
        {
          comment: 'Absolutely love this program! The final product looks so professional!',
          name: 'Michelle C, ANA'
        },
        {
          comment: 'This is the best and easiest site I have ever used to create my flyers!',
          name: 'Lisa S, SRT Painting'
        },
        {
          comment:
            'Made my marketing needs 100x easier! I downloaded and printed and it looks great!',
          name: 'Melissa, H'
        },
        {
          comment:
            'Best website to create any sort of marketing material for any business small or large.',
          name: 'Michael, L, Speedy Softwash'
        },
        {
          comment:
            'Great website! Extremely user friendly and can address any & all your marketing needs!',
          name: 'Sean E, Four Points Insurance'
        }
      ],
      showLeftCol: false
    };
  },

  computed: {
    title() {
      if (this.showConfirmation) return 'Welcome';
      return this.iframed ? 'Upgrade Plan' : 'Subscription Checkout';
    },

    titleIcon() {
      if (this.showConfirmation || this.iframed) return this.crownIcon;
      return '';
    },

    selectedPlan() {
      return this.$store.getters['subscription/selectedPlan'];
    },

    upgradeInvoice() {
      return this.$store.state.subscription.invoicePreview;
    },

    doFirstMonthDiscount() {
      if (this.$store.state.user.hasOrdered) return false;
      return this.selectedPlan.isMonthly && this.selectedPlan.firstMonthPromoCodeId;
    },

    total() {
      if (this.doFirstMonthDiscount) return this.selectedPlan.firstMonthCost;
      if (this.upgradeInvoice) return this.upgradeInvoice.total;
      return this.selectedPlan?.cost;
    },

    totalInSubunit() {
      if (this.doFirstMonthDiscount) return Math.round(this.selectedPlan.firstMonthCost * 100);
      if (this.upgradeInvoice) return Math.round(this.upgradeInvoice.total * 100);
      return Math.round(this.selectedPlan?.cost * 100);
    },

    totalFormatted() {
      if (this.doFirstMonthDiscount) return this.selectedPlan.firstMonthCostFormatted;
      if (this.upgradeInvoice) return this.upgradeInvoice.totalFormatted;
      return this.selectedPlan?.costFormatted;
    },

    showUSA() {
      return !this.$store.state.user.profile.country.isInternational;
    },

    ...mapGetters({
      drawerOpenedAt: 'drawers/lastOpenedAt'
    })
  },

  watch: {
    drawerOpenedAt() {
      if (this.drawerId === this.$store.getters['drawers/topDrawer']?.id) {
        if (this.iframed) {
          if (window.innerWidth > 780) {
            this.showLeftCol = true;
          } else {
            this.showLeftCol = false;
          }
        }
      }
    }
  },

  methods: {
    changePlan() {
      this.changingPlan = true;
      this.error = '';
      var payload = {
        planId: this.selectedPlan.planId,
        paymentMethodId: this.paymentMethodId ?? ''
      };
      this.$store
        .dispatch('subscription/changePlan', payload)
        .then(async (result) => {
          if (result.success) {
            // todo: analytics
            await this.$store.dispatch('subscription/getDetail');
            this.$store.commit('drawers/CLOSE_ALL');
            this.$root.$snackbarSuccess('Upgrade successful!');
          } else {
            this.error = result.message;
          }
        })
        .finally(() => {
          setTimeout(() => {
            this.changingPlan = false;
          }, 2000);
        });
    },

    onSuccess() {
      // todo: analytics
      // wrap this in a timeout just to give the webhooks enough time to fire
      if (!this.iframed && !this.$store.state.user.hasSubscribed) {
        var gTagConversionData = {
          event_category: 'subscription',
          send_to: 'AW-971600927/HEcCCLSCioEZEJ_opc8D',
          value: this.total,
          currency: this.selectedPlan.currency.code
        };
        console.log('gTag conversion: ', gTagConversionData);
        this.$gtag.event('conversion', gTagConversionData);
      }
      setTimeout(() => {
        if (this.iframed) {
          this.showConfirmation = true;
          this.$mixpanel.trackEvent(
            ANALYTICS_EVENTS.SUBSCRIBE.NAME,
            ANALYTICS_EVENTS.SUBSCRIBE.ACTIONS.SUBSCRIBE_SUCCESS_IFRAME
          );
          window.parent.postMessage({ type: 'subscribe-success' }, '*');
        } else {
          this.$store.commit('user/UPDATE_HAS_ORDERED');
          this.$store.commit('drawers/CLOSE_ALL');
          this.$router.go(ROUTES.ORDER_HISTORY.PATH);
        }
      }, 2000);
    },

    onAccountCreated(payload) {
      if (this.iframed) {
        window.parent.postMessage({ type: 'guest-to-account', data: payload }, '*');
      }
    },

    onClose() {
      this.$store.commit('drawers/CLOSE_ALL');
    },

    help() {
      if (this.iframed) {
        window.parent.postMessage({ type: 'open-help' }, '*');
      } else {
        this.$eventBus.$emit(APP_EVENTS.OPEN_HELPSCOUT, { ask: true });
      }
    }
  }
};
</script>

<style scoped></style>
