<template>
  <v-sheet
    v-ripple
    class="bordered border-light rounded-lg px-3 py-2 hover-pointer"
    @click="clickButton"
  >
    <slot name="top" />
    <v-row>
      <v-col
        cols="auto"
        class="body-1"
      >
        <div class="font-weight-bold pt-1">
          {{ address.line1 }}
        </div>
        <div v-if="address.line2">
          {{ address.line2 }}
        </div>
        <div class="pb-1">
          {{ address.city }} {{ address.state || state_abbr(address.stateProvinceId) }}
          {{ address.postalCode }}
        </div>
      </v-col>
      <v-spacer />
      <v-col
        cols="auto"
        class="my-auto"
      >
        <v-btn
          icon
          color="black"
        >
          <v-icon :small="buttonIconSmall">
            {{ buttonIcon }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex';
import { mdiChevronRight } from '@mdi/js';
export default {
  props: {
    address: { type: Object, default: () => {} },
    buttonIcon: { type: String, default: mdiChevronRight },
    buttonIconSmall: { type: Boolean, default: false }
  },
  data: () => ({
    loading: false
  }),
  computed: {
    ...mapGetters({ state_abbr: 'lookups/state_abbr' })
  },
  mounted() {},
  methods: {
    clickButton() {
      this.loading = true;
      this.$emit('click');
    }
  }
};
</script>
