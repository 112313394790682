<template>
  <div class="bg-gradient2 w-full h-full d-flex align-center justify-center black--text">
    <span class="text-h5">👋</span>
    <span class="font-weight-medium body-1"
      >Save 10% on prints! Use code
      <span class="coupon font-weight-bold px-1 ml-1">SAVE10FIRST</span>
    </span>
  </div>
</template>

<script>
import { mdiCash } from '@mdi/js';

export default {
  name: 'PrintPromoAlert',

  data() {
    return {
      icon: mdiCash
    };
  },

  computed: {},

  methods: {}
};
</script>

<style>
.coupon {
  border: dashed 2px;
}
</style>
