<template>
  <v-sheet class="bordered rounded border-light">
    <v-row dense>
      <v-col
        cols="auto"
        class="my-auto pl-4"
      >
        <v-icon small>
          {{ docIcon }}
        </v-icon>
      </v-col>
      <v-col class="my-auto py-4 text-truncate">
        {{ campaign.addressFileName }}
      </v-col>
      <v-spacer class="spacer-min-width" />
      <v-col
        cols="auto"
        class="my-auto"
      >
        <v-btn
          text
          color="brand"
          @click="$emit('reset')"
        >
          Change list >
        </v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex';
import { mdiFileDocument } from '@mdi/js';
export default {
  name: 'SnailblastUploadChange',

  data: () => ({ docIcon: mdiFileDocument }),

  computed: { ...mapGetters({ campaign: 'snailblast/campaign/edit_campaign' }) },

  methods: {}
};
</script>
