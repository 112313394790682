<template>
  <Drawer
    :id="drawerId"
    title="Set Default Payment Method"
    padded
  >
    <div>
      <PaymentSelector @paymentMethodSelected="(id) => (paymentMethodId = id)" />
      <v-btn
        color="primary"
        block
        large
        elevation="0"
        :loading="processing"
        :disabled="!paymentMethodId"
        class="mt-5"
        @click="setPaymentMethod"
      >
        Save Payment Method
      </v-btn>
      <v-alert
        v-show="error"
        color="error"
        outlined
        class="mt-3"
        v-text="error"
      />
    </div>
  </Drawer>
</template>

<script>
import Drawer from '../../components/layout/Drawer';
import PaymentSelector from '../../components/payments/PaymentSelector';
import { DRAWERS } from '../../utils/constants';

export default {
  name: 'DefaultPaymentMethodDrawer',

  components: {
    Drawer,
    PaymentSelector
  },

  data() {
    return {
      paymentMethodId: 0,
      drawerId: DRAWERS.SET_DEFAULT_PAYMENT_DRAWER,
      error: '',
      processing: false
    };
  },

  computed: {},

  methods: {
    setPaymentMethod() {
      this.processing = true;
      this.$store
        .dispatch('subscription/setDefaultPayment', this.paymentMethodId)
        .then(async (result) => {
          if (result.success) {
            await this.$store.dispatch('subscription/getDetail');
            this.$store.commit('drawers/CLOSE_DRAWER', this.drawerId);
            this.$root.$snackbarSuccess('Default payment set successfully');
          } else {
            this.error = result.message;
          }
        })
        .finally(() => {
          this.processing = false;
        });
    }
  }
};
</script>

<style scoped></style>
