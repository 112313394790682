<template>
  <v-btn
    color="brand"
    elevation="0"
    :outlined="$vuetify.breakpoint.mobile"
    rounded
    class="mr-3 white--text"
    :href="bindings.href"
    v-on="bindings.onClick !== null ? { click: bindings.onClick } : {}"
  >
    <v-icon>
      {{ plusIcon }}
    </v-icon>
    New Design
  </v-btn>
</template>

<script>
import { mdiPlus } from '@mdi/js';
import { DRAWERS } from '../../utils/constants';

export default {
  name: 'NewDesignButton',

  data() {
    return {
      plusIcon: mdiPlus
    };
  },

  computed: {
    catalogUrl() {
      return this.$store.getters['config/catalogUrl'];
    },

    useCatalogDrawer() {
      return this.$store.state.config.inDevEnv || !this.$store.getters['user/isMCS'];
    },

    bindings() {
      return {
        href: this.useCatalogDrawer ? null : this.catalogUrl,
        onClick: this.useCatalogDrawer ? this.openCatalogDrawer : null
      };
    }
  },

  methods: {
    openCatalogDrawer() {
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.CATALOG_DRAWER);
    }
  }
};
</script>

<style></style>
