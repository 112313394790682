<template>
  <v-dialog
    v-if="profile.requirePasswordChange"
    v-model="dialog"
    persistent
    max-width="290"
  >
    <v-card>
      <v-card-title class="text-h5 mb-4"> Change Password </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-text-field
            v-model="password"
            :rules="[$rules.required, $rules.minlength(password, 6)]"
            outlined
            type="password"
            label="New Password"
          />
          <v-text-field
            v-model="confirmPassword"
            :rules="[confirm]"
            outlined
            type="password"
            label="Confirm Password"
          />
          <v-btn
            block
            color="black"
            class="white--text"
            :loading="saving"
            @click="save"
          >
            Save
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'ForcePassword',
  data() {
    return {
      dialog: true,
      password: '',
      confirmPassword: '',
      saving: false
    };
  },
  computed: { ...mapGetters({ profile: 'user/profile' }) },
  methods: {
    confirm(val) {
      return val === this.password || "Password doesn't match";
    },
    save() {
      if (this.$refs.form.validate()) {
        this.saving = true;
        this.$store
          .dispatch('user/updatePassword', { password: this.password })
          .then((data) => {
            if (this.$refs.form) {
              this.$refs.form.reset();
            }
            this.$root.$snackbarSuccess('Password updated');
          })
          .catch((error) => {
            this.logError(error, 'An error occurred while changing your password');
          })
          .finally(() => {
            this.saving = false;
          });
      }
    }
  }
};
</script>
