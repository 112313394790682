<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    persistent
    :style="{ zIndex: options.zIndex }"
    :content-class="`${options.top ? 'align-self-start' : ''} rounded-xl`"
    @keydown.esc="cancel"
    @keydown.enter.prevent="agree"
  >
    <v-card>
      <v-card-title
        class="text-h6 d-block text-center"
        primary-title
      >
        {{ title }}
      </v-card-title>
      <v-divider />
      <v-card-text class="py-5">
        <div
          v-show="!!message"
          v-html="message"
        />
        <v-select
          v-if="options.selectList.options.length"
          v-model="selectValue"
          class="my-3"
          :items="options.selectList.options"
          :label="options.selectList.label"
          item-text="name"
          item-value="id"
          filled
          dense
          hide-details
        />
        <v-form
          v-if="options.showTextInputField"
          ref="form"
          v-model="textInputValid"
          lazy-validation
        >
          <v-text-field
            v-model="textInputValue"
            class="mt-3"
            outlined
            :rules="options.textFieldRules"
          />
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-btn
          color="error"
          text
          @click.native="cancel"
        >
          {{ options.cancelText }}
        </v-btn>
        <v-spacer />
        <v-btn
          color="primary"
          text
          :disabled="!textInputValid"
          @click.native="agree"
        >
          <span v-if="options.selectList.options.length">
            <template v-if="options.okText">{{ options.okText }}</template>
            <template v-else>Select</template>
          </span>
          <span v-else>{{ options.okText }}</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * Vuetify Confirm Dialog component
 *
 * Insert component where you want to use it:
 * <confirm ref="confirm"></confirm>
 *
 * Call it:
 * this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
 * Or use await:
 * if (await this.$refs.confirm.open('Delete', 'Are you sure?', { color: 'red' })) {
 *   // yes
 * }
 * else {
 *   // cancel
 * }
 *
 * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
 * <template>
 *   <v-app>
 *     ...
 *     <confirm ref="confirm"></confirm>
 *   </v-app>
 * </template>
 *
 * mounted() {
 *   this.$root.$confirm = this.$refs.confirm.open
 * }
 */
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    selectValue: null,
    textInputValue: '',
    textInputValid: true,
    options: {
      color: 'primary',
      width: 400,
      zIndex: 200,
      top: true,
      okText: 'Ok',
      cancelText: 'Cancel',
      selectList: {
        options: [],
        label: ''
      },
      textFieldRules: [],
      showTextInputField: false
    }
  }),
  methods: {
    open(title, message, options) {
      this.dialog = true;
      this.$store.commit('config/INCREMENT_DIALOGS_OPEN');
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      if (this.options.selectList.options.length) { this.selectValue = this.options.selectList.options[0].id; }
      if (this.options.showTextInputField) this.textInputValue = this.options.textInputValue;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      if (this.options.selectList.options.length) {
        this.resolve(this.selectValue);
      } else if (this.options.showTextInputField) {
        this.resolve(this.textInputValue);
      } else {
        this.resolve(true);
      }
      this.dialog = false;
      this.$store.commit('config/DECREMENT_DIALOGS_OPEN');
      this.$reset();
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
      this.$store.commit('config/DECREMENT_DIALOGS_OPEN');
      this.$reset();
    },
    $reset() {
      this.options.okText = 'Ok';
      this.options.cancelText = 'Cancel';
      this.options.width = 400;
      this.options.top = true;
      this.options.selectList.options = [];
      this.options.showTextInputField = false;
      this.options.textInputValue = '';
      this.options.textFieldRules = [];
      this.textInputValid = true;
    }
  }
};
</script>
