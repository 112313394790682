<template>
  <div
    class="h-full d-flex flex-column"
    :style="{ width: expanded ? '418px' : '100%' }"
  >
    <div>
      <div class="d-flex mb-4 mt-2">
        <v-select
          v-model="databaseId"
          outlined
          :items="databases"
          item-text="name"
          item-value="id"
          hide-details
          placeholder="Select your list type"
          label="Target Audience"
          :disabled="campaign_idea"
          @change="changeDatabase"
        >
          <template #selection="{ item }">
            <span class="mr-2">{{ getListPurchaseDatabaseIcon(item.id) }}</span>
            {{ item.name }}
          </template>
          <template #item="{ item, on, attrs }">
            <v-list-item
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-icon class="mr-2">
                {{ getListPurchaseDatabaseIcon(item.id) }}
              </v-list-item-icon>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-select>
        <v-btn
          v-if="items.length && !forceFilters && !campaign_idea"
          :outlined="!showFilters"
          :class="{ 'border-light': !showFilters, 'white--text': showFilters }"
          :color="showFilters ? 'black' : undefined"
          height="56"
          class="ml-4"
          @click="showFilters = !showFilters"
        >
          <v-icon
            class="mr-1"
            small
          >
            {{ showFilters ? mapIcon : filterIcon }}
          </v-icon>
          <v-badge
            v-if="!showFilters"
            color="green"
            inline
            :content="filters.length.toString()"
          >
            Filters
          </v-badge>
          <template v-else> Map </template>
        </v-btn>
      </div>
      <div
        v-if="campaign_idea && filters && filters.length"
        class="mb-4"
      >
        <div
          v-for="(filter, i) in filters"
          :key="i"
          class="py-2"
        >
          <v-text-field
            :value="filter.text || filter.value"
            outlined
            hide-details
            readonly
            disabled
            :label="filter.field.name"
            :title="filter.text || filter.value"
          >
            <template #append>
              <v-icon color="light darken-2">{{ lockIcon }}</v-icon>
            </template>
          </v-text-field>
        </div>
        <div class="font-italic caption">
          * Filters have been set by your admin and cannot be edited
        </div>
      </div>
      <ListPurchaseMap
        v-if="!expanded && !showFilters"
        id="audiencePreviewMap"
        ref="map"
        height="250px"
        class="bordered border-light rounded-t"
        @expand="$emit('expand')"
      />
    </div>
    <div
      v-if="!showFilters"
      class="d-flex h-full flex-column autoscroll-y bordered border-light rounded-b"
    >
      <v-data-table
        class="flex-grow-1 d-flex h-full flex-column autoscroll-y"
        :loading="loading"
        :headers="headers"
        :items="items"
        item-key="zipRouteId"
        disable-pagination
        hide-default-footer
        fixed-header
        mobile-breakpoint="0"
      >
        <template #item.selected="{ index }">
          <v-icon
            class="mr-1"
            @click="removeListPurchaseRadius(index)"
          >
            {{ checkIcon }}
          </v-icon>
        </template>
        <template #item.addressCount="{ item }">
          <v-progress-circular
            v-if="count_loading"
            :size="25"
            color="brand"
            indeterminate
          />
          <div v-else>
            {{ item.addressCount | separator }}
          </div>
        </template>
        <template #item.radius.radius="{ item }">
          <RadiusSelect
            :value="item.radius.radius"
            @apply="applyRadius(item, $event)"
            @open="openRadius(item)"
            @close="closeRadius"
          />
        </template>
      </v-data-table>
    </div>
    <Filters
      v-if="showFilters"
      @back="filtersGoBack"
    />
  </div>
</template>

<script>
import Filters from '@/components/print/checkout/snailblast/listpurchase/filters/Index';
import ListPurchaseMap from '@/components/print/checkout/snailblast/listpurchase/Map';
import RadiusSelect from '@/components/print/checkout/snailblast/RadiusSelect';
import { ANALYTICS_EVENTS, APP_EVENTS, DRAWERS, ENUMS } from '@/utils/constants';
import snailblast from '@/utils/mixins/snailblast';
import { mdiCheckboxMarked, mdiFilterVariant, mdiLock, mdiMapMarkerRadius } from '@mdi/js';
import { mapGetters } from 'vuex';
export default {
  name: 'SnailblastListPurchase',

  components: { ListPurchaseMap, Filters, RadiusSelect },

  mixins: [snailblast],

  props: {
    expanded: { type: Boolean, default: false },
    forceFilters: { type: Boolean, default: false }
  },

  data: () => ({
    showFilters: false,
    loading: false,
    loading_count: false,
    lockIcon: mdiLock,
    headers: [
      { text: '', value: 'selected', sortable: false, class: 'px-2', cellClass: 'px-2 py-4' },
      {
        text: 'Location',
        value: 'radius.text',
        sortable: false,
        class: 'px-2',
        cellClass: 'px-2 py-4'
      },
      {
        text: 'Addresses',
        value: 'addressCount',
        sortable: false,
        class: 'px-2',
        cellClass: 'px-2 py-4'
      },
      {
        text: 'Radius',
        value: 'radius.radius',
        sortable: false,
        class: 'px-2',
        cellClass: 'px-2 py-4'
      }
    ],
    checkIcon: mdiCheckboxMarked,
    filterIcon: mdiFilterVariant,
    mapIcon: mdiMapMarkerRadius
  }),

  computed: {
    databaseId: {
      get() {
        return this.$store.getters['snailblast/campaign/list_purchase_database_id'];
      },
      set(val) {
        this.setListPurchaseField('databaseId', val, true);
        this.setListPurchaseField(
          'filters',
          this.fields(this.databaseId)
            .filter((x) => x.typeId === ENUMS.SNAILBLAST.LIST_PURCHASE_FIELD_TYPES.BOOL)
            .map((x) => ({ field: x, value: '1', text: 'Yes' }))
        );
      }
    },
    saving: {
      get() {
        return this.$store.getters['snailblast/campaign/saving'];
      },
      set(val) {
        this.$store.dispatch('snailblast/campaign/setSaving', val);
      }
    },
    items() {
      return this.list_purchases.filter((x) => x.radius && x.radius.shape && x.radius.text);
    },
    ...mapGetters({
      campaign: 'snailblast/campaign/edit_campaign',
      databases: 'snailblast/lookup/list_purchase_databases',
      filters: 'snailblast/campaign/list_purchase_filters',
      fields: 'snailblast/lookup/list_purchase_fields',
      list_purchases: 'snailblast/campaign/list_purchases',
      list_purchase_address_count: 'snailblast/campaign/list_purchase_address_count',
      count_loading: 'snailblast/campaign/count_loading',
      price: 'printcart/price',
      analytics_data: 'snailblast/campaign/analytics_data',
      campaign_idea: 'printcart/campaign_idea'
    })
  },

  created() {
    this.$eventBus.$off(APP_EVENTS.GET_LIST_PURCHASE_COUNT);
    this.$eventBus.$on(APP_EVENTS.GET_LIST_PURCHASE_COUNT, (data) => {
      this.getCount();
    });
    if (this.forceFilters) {
      this.showFilters = true;
    }
  },

  methods: {
    changeDatabase() {
      this.$mixpanel.trackEvent(
        ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
        ANALYTICS_EVENTS.MAILING_SERVICE.ACTIONS.LIST_TYPE_SET,
        this.analytics_data
      );
    },
    getCount() {
      this.loading_count = true;
      this.$store
        .dispatch('snailblast/campaign/getListPurchaseCount', this.list_purchases)
        .then(async (val) => {
          if (val.length) {
            this.setListPurchases(val, true);
          }
          this.$eventBus.$emit(APP_EVENTS.GET_EDIT_ITEM_PRICE, { source: 'getListPurchaseCount' });
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.loading_count = false;
        });
    },
    applyRadius(item, radius) {
      item.radius.radius = radius;
      this.getCount();
    },
    openRadius(item) {
      this.$refs.map.zoomTo(item.radius);
    },
    closeRadius() {
      this.$refs.map.zoomToBounds();
    },
    save() {
      this.saving = true;
      this.$store
        .dispatch('snailblast/campaign/saveListPurchases', {
          campaign: this.campaign,
          list_purchases: this.list_purchases
        })
        .then(() => {
          this.$store.commit('drawers/CLOSE_DRAWER', DRAWERS.SNAILBLAST_AUDIENCE);
          this.$mixpanel.trackEvent(
            ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
            ANALYTICS_EVENTS.MAILING_SERVICE.ACTIONS.SAVE_COMPLETE,
            Object.assign(this.analytics_data, {
              mailing_service: 'list_builder',
              audience_size: this.list_purchase_address_count,
              price: this.price
            })
          );
          this.$store.dispatch('snailblast/campaign/trackSaveEvent', this.campaign);
        })
        .catch((error) => {
          this.logError(error, 'Error while trying to save');
        })
        .finally(() => {
          this.saving = false;
        });
    },
    filtersGoBack() {
      if (!this.forceFilters) {
        this.showFilters = false;
      }
    }
  }
};
</script>
