import { orderBy } from 'lodash';

const state = {
  categories: [],
  core_products: [],
  design_groups: []
};

const getters = {
  categories(state) {
    return state.categories || [];
  },
  printableCategories(state) {
    var arr = state.categories.filter((x) => x.startQuantity !== null) || [];
    if (!arr.length) return arr;
    return orderBy(arr, [(arr) => arr.name], ['asc']);
  },
  core_products(state) {
    return (categoryId) => {
      return state.core_products.filter((x) => x.categoryId === categoryId);
    };
  },
  core_product(state) {
    return (id) => {
      console.log(state.core_products);
      return state.core_products.filter((x) => x.id === id)[0];
    };
  },
  core_products_printable(state, getters) {
    return (categoryId) => {
      return state.core_products.filter((x) => x.categoryId === categoryId && x.printable);
    };
  },
  design_groups(state) {
    return state.design_groups || [];
  }
};

const actions = {
  getCategories({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('data/getAppData')
        .then((response) => {
          commit('SET_CATEGORIES', response.data.categories);
          commit('SET_CORE_PRODUCTS', response.data.coreProducts);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  get({ commit }) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .get('templates/get')
        .then((response) => {
          commit('SET_TEMPLATES', response.data.templates);
          commit('SET_DESIGN_GROUPS', response.data.designGroups);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  save({ commit }, template) {
    return new Promise((resolve, reject) => {
      this._vm.$api
        .post('templates/save', template)
        .then((response) => {
          commit('SET_TEMPLATES', response.data.templates);
          commit('SET_DESIGN_GROUPS', response.data.designGroups);
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

const mutations = {
  SET_CATEGORIES(state, payload) {
    state.categories = payload;
  },
  SET_CORE_PRODUCTS(state, payload) {
    state.core_products = payload;
  },
  SET_DESIGN_GROUPS(state, payload) {
    state.design_groups = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
