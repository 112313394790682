import { sortBy } from 'lodash';
export default {
  computed: {
    parentId() {
      let val = this.$route.params.parentId;
      if (val && val.length > 0) {
        val = parseInt(val);
      }
      return val || null;
    },
    collectionId() {
      const id = this.$store.getters['admin/collections/id'];

      const collections =
        sortBy(this.$store.getters['admin/collections/list'], [
          function (o) {
            return id !== o.id;
          }
        ]);
      let val = collections.map(x => x.id)[0];
      if (val && val.length > 0) {
        val = parseInt(val);
      }
      return val || null;
    }
  },
  methods: {
    getPublishedColor(published, hex) {
      if (published) {
        return hex ? this.$vuetify.theme.themes.light.green : 'green';
      }
      return hex ? this.$vuetify.theme.themes.light.warning : 'warning';
    }
  }
};
