<template>
  <v-row v-if="!showEdit">
    <v-col cols="12">
      <v-btn
        block
        outlined
        x-large
        class="font-weight-bold"
        style="border: solid 1.5px"
        @click="edit"
      >
        <v-icon
          small
          class="mr-2"
        >
          {{ addIcon }}
        </v-icon>
        Add Address
      </v-btn>
    </v-col>
    <v-col
      v-for="address in addressesToShow"
      :key="address.id"
      cols="12"
    >
      <v-sheet
        v-ripple
        class="bordered border-light rounded px-3 py-4 hover-pointer"
        @click="edit(address)"
      >
        <v-row dense>
          <v-col>
            {{ address.line1 }}
            <template v-if="address.line2">
              {{ address.line2 }}
            </template>
            {{ address.city }}, {{ address.state }} {{ address.postalCode }}
          </v-col>
          <v-spacer class="limited-spacer" />
          <v-col
            v-if="address.isDefault"
            cols="auto"
            class="my-auto"
          >
            <v-icon
              small
              color="#FFCC46"
            >
              {{ starIcon }}
            </v-icon>
          </v-col>
          <v-col
            cols="auto"
            class="my-auto"
          >
            <v-icon
              small
              class="mr-2"
            >
              {{ editIcon }}
            </v-icon>
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
    <Portal :to="`drawerAppend-${drawerId}`">
      <v-row class="border border-t border-light pa-2 ma-0">
        <v-col cols="12">
          <v-btn
            block
            dark
            x-large
            elevation="0"
            @click="$emit('close')"
          >
            {{ backText }}
          </v-btn>
        </v-col>
      </v-row>
    </Portal>
  </v-row>
  <AddressForm
    v-else
    :id="addressId"
    :drawerId="drawerId"
    @save="handleSave"
  />
</template>

<script>
import { mdiPencil, mdiPlusCircle, mdiStar } from '@mdi/js';
import { orderBy } from 'lodash';
import { mapGetters } from 'vuex';
import AddressForm from './Form.vue';
export default {
  name: 'ManageAddresses',
  components: { AddressForm },
  props: { drawerId: { type: String, default: '' }, backText: { type: String, default: '' } },
  data() {
    return {
      addIcon: mdiPlusCircle,
      editIcon: mdiPencil,
      starIcon: mdiStar,
      showEdit: false,
      addressId: null
    };
  },
  computed: {
    addressesToShow() {
      return orderBy(this.addresses, ['isDefault', 'id'], ['desc', 'asc']);
    },
    ...mapGetters({ addresses: 'user/addresses' })
  },
  methods: {
    edit(address) {
      this.addressId = address ? address.id : null;
      this.showEdit = true;
    },
    handleSave() {
      this.showEdit = false;
      this.addressId = null;
      this.$emit('close');
    }
  }
};
</script>
