<template>
  <v-btn
    icon
    small
    :class="[cartItems.length > 0 ? 'mr-5' : 'mr-3']"
    color="black"
    :disabled="disabled"
    @click="click"
  >
    <v-badge
      :value="cartItems.length > 0"
      color="brand"
      :content="cartItems.length"
    >
      <v-icon
        size="18"
        class="thick"
      >
        {{ cartIcon }}
      </v-icon>
    </v-badge>
  </v-btn>
</template>

<script>
import { mdiCartOutline } from '@mdi/js';
import { DRAWERS, PRINT_CHECKOUT_SCREENS } from '../../utils/constants';
import { mapGetters } from 'vuex';

export default {
  name: 'CartButton',

  data() {
    return {
      cartIcon: mdiCartOutline
    };
  },

  computed: {
    disabled() {
      return false;
    },

    ...mapGetters({
      cartItems: 'printcart/cart_lines'
    })
  },

  mounted() {
    this.$store.dispatch('printcart/getCart');
  },

  methods: {
    click() {
      this.$store.commit('drawers/SET_DATA', {
        drawerId: DRAWERS.PRINT_CHECKOUT,
        data: { screen: PRINT_CHECKOUT_SCREENS.CART }
      });
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.PRINT_CHECKOUT);
    }
  }
};
</script>

<style></style>
