<template>
  <div
    v-if="uploading"
    class="d-flex h-full flex-column autoscroll-y bordered border-light rounded-b"
  >
    <Loading
      path="//res.cloudinary.com/mycreativeshop-blog/raw/upload/v1716519622/lf20_6gdptzee.json"
      :width="200"
      :height="200"
      title="Uploading your list"
      subtitle="This can take a minute depending on the size of your list."
    />
  </div>
  <div
    v-else
    class="w-full mt-4"
  >
    <v-btn
      v-if="is_eddm || is_list_purchase"
      block
      outlined
      x-large
      class="font-weight-bold"
      style="border: solid 1.5px"
      @click="add"
    >
      <v-icon
        small
        class="mr-2"
      >
        {{ addIcon }}
      </v-icon>
      New Audience
    </v-btn>
    <template v-if="is_upload">
      <v-btn
        block
        outlined
        x-large
        class="font-weight-bold"
        style="border: solid 1.5px"
        :loading="uploading"
        @click="showFileSelect"
      >
        <v-icon
          small
          class="mr-2"
        >
          {{ uploadIcon }}
        </v-icon>
        Upload List
      </v-btn>
      <div class="mt-2">
        Need help formatting -
        <span
          class="brand--text hover-pointer"
          @click="$emit('guide')"
          >Read guide ></span
        >
      </div>
      <input
        ref="hiddenFileUpload"
        type="file"
        style="display: none"
        accept=".xls,.xlsx,.csv"
        @change="clickFileSelected"
      />
    </template>
    <template v-if="previous_campaigns.length">
      <v-divider class="my-4" />
      <div class="mt-4">
        <div class="font-weight-bold mb-2">Start from a previous save</div>
        <v-row>
          <v-col
            v-if="loading"
            cols="12"
          >
            <v-skeleton-loader
              type="list-item-avatar, list-item-avatar,list-item-avatar,list-item-avatar"
            />
          </v-col>
          <template v-else>
            <v-col
              v-for="item in previous_campaigns"
              :key="item.id"
              cols="12"
            >
              <v-card
                class="rounded bordered border-light"
                :disabled="saving"
                @click="select(item)"
              >
                <v-row>
                  <v-col
                    v-if="item.imageUrl"
                    cols="auto"
                    class="my-auto ml-2 pr-0"
                  >
                    <v-img
                      :src="item.imageUrl"
                      width="100"
                      contain
                    />
                  </v-col>
                  <v-col cols="auto">
                    <v-card-title class="pt-2">
                      {{ item.name }}
                    </v-card-title>
                    <v-card-subtitle class="pb-2">
                      {{ item.updatedDate | normalDateWithTime }}
                    </v-card-subtitle>
                    <v-card-text class="pb-2">
                      <span>
                        <v-icon small>{{ audienceIcon }}</v-icon>
                        {{ item.recipientCount | separator }}
                      </span>
                    </v-card-text>
                  </v-col>
                  <v-spacer />
                </v-row>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mdiPlusCircle, mdiMailbox, mdiAccountSupervisorCircle, mdiCloudUpload } from '@mdi/js';
import { ENUMS, APP_EVENTS, ANALYTICS_EVENTS } from '@/utils/constants';
import Loading from '@/components/print/checkout/snailblast/Loading';
export default {
  name: 'SnailblastSelectAudience',

  components: { Loading },

  data() {
    return {
      addIcon: mdiPlusCircle,
      mailboxIcon: mdiMailbox,
      audienceIcon: mdiAccountSupervisorCircle,
      uploadIcon: mdiCloudUpload,
      loading: false,
      saving: false,
      uploading: false,
      enums: ENUMS,
      uploadedFiles: []
    };
  },

  computed: {
    campaign: {
      get() {
        return this.$store.getters['snailblast/campaign/edit_campaign'];
      },
      set(val) {
        this.$store.dispatch('snailblast/campaign/setEditCampaign', val);
      }
    },

    ...mapGetters({
      previous_campaigns: 'snailblast/campaign/previous',
      campaign_routes: 'snailblast/campaign/routes',
      is_eddm: 'snailblast/campaign/is_eddm',
      is_upload: 'snailblast/campaign/is_upload',
      is_list_purchase: 'snailblast/campaign/is_list_purchase',
      analytics_data: 'snailblast/campaign/analytics_data'
    })
  },

  mounted() {
    if (!this.previous_campaigns.length && !this.is_upload) {
      this.add();
    }
  },

  methods: {
    add() {
      this.$mixpanel.trackEvent(
        ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
        ANALYTICS_EVENTS.MAILING_SERVICE.ACTIONS.NEW,
        this.analytics_data
      );
      this.$emit('add');
    },
    async select(item) {
      if (item.isDraft && !item.usedInPrintCart) {
        await this.$store.dispatch('snailblast/campaign/refresh', item.id);
      }
      if (this.is_eddm) {
        this.selectEddm(item);
      } else if (this.is_upload) {
        this.selectUpload(item);
      } else if (this.is_list_purchase) {
        this.selectListPurchase(item);
      }
    },
    selectEddm(item) {
      this.$mixpanel.trackEvent(
        ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
        ANALYTICS_EVENTS.MAILING_SERVICE.ACTIONS.EXISTING,
        this.analytics_data
      );
      this.saving = true;
      this.$store
        .dispatch('snailblast/campaign/getRoutes', item.id)
        .then(() => {
          this.campaign = Object.assign(this.campaign, {
            recipientCount: item.recipientCount
          });
          this.$eventBus.$emit(APP_EVENTS.GET_EDIT_ITEM_PRICE, { source: 'selectEddm' });
          Promise.all([
            this.$store.dispatch('snailblast/routes/setRoutes', this.campaign_routes),
            this.$store.dispatch('snailblast/routes/selectRoutesFromTable', this.campaign_routes)
          ])
            .then(() => {
              this.$emit('add');
            })
            .catch((error) => {
              this.logError(error, 'Error setting selected routes');
            })
            .finally(() => {
              this.saving = false;
            });
        })
        .catch((error) => {
          this.logError(error, 'Error getting routes');
          this.saving = false;
        });
    },
    selectUpload(item) {
      this.$mixpanel.trackEvent(
        ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
        ANALYTICS_EVENTS.MAILING_SERVICE.ACTIONS.EXISTING,
        this.analytics_data
      );
      this.saving = true;
      this.$store
        .dispatch('snailblast/campaign/copyAddresses', item.id)
        .then(() => {
          this.campaign = Object.assign(this.campaign, {
            validAddresses: item.validAddresses,
            recipientCount: item.recipientCount
          });
          this.$eventBus.$emit(APP_EVENTS.GET_EDIT_ITEM_PRICE, { source: 'selectUpload' });
          this.$emit('add');
        })
        .catch((error) => {
          this.logError(error, 'Error setting addresses');
        })
        .finally(() => {
          this.saving = false;
        });
    },
    selectListPurchase(item) {
      this.$mixpanel.trackEvent(
        ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
        ANALYTICS_EVENTS.MAILING_SERVICE.ACTIONS.EXISTING,
        this.analytics_data
      );
      this.saving = true;
      this.$store
        .dispatch('snailblast/campaign/getListPurchases', item.id)
        .then(() => {
          this.campaign = Object.assign(this.campaign, {
            recipientCount: item.recipientCount,
            listPurchasecount: item.listPurchaseCount
          });
          this.$eventBus.$emit(APP_EVENTS.GET_EDIT_ITEM_PRICE, { source: 'selectListPurchase' });
          this.$emit('add');
        })
        .catch((error) => {
          this.logError(error, 'Error setting list purchases');
        })
        .finally(() => {
          this.saving = false;
        });
    },
    showFileSelect() {
      this.$mixpanel.trackEvent(
        ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
        ANALYTICS_EVENTS.MAILING_SERVICE.ACTIONS.NEW,
        this.analytics_data
      );
      this.uploadedFiles = [];
      this.$refs.hiddenFileUpload.click();
    },
    clickFileSelected(e) {
      this.setFiles(e.target.files);
    },
    setFiles(files) {
      if (files) {
        Array.from(files).forEach((element) => {
          this.uploadedFiles.push(element);
        });
        this.uploadFiles();
      }
    },
    async uploadFiles() {
      this.uploading = true;
      const action = 'snailblast/campaign/uploadMailingList';
      const body = { campaignId: this.campaign.id, file: this.uploadedFiles[0] };
      if (!this.campaign.id) {
        body.campaignId = await this.$store.dispatch('snailblast/campaign/save', this.campaign);
      }
      this.$store
        .dispatch(action, body)
        .then((res) => {
          this.$emit('add');
        })
        .catch((error) => {
          this.logError(error, 'Error uploading mailing list');
        })
        .finally(() => {
          this.uploading = false;
        });
    }
  }
};
</script>
