<template>
  <div class="h-full d-flex flex-column autoscroll-y mt-4 overflow-hidden">
    <UploadChange @reset="$emit('reset')" />
    <div class="d-flex h-full flex-column bordered border-light rounded autoscroll-y mt-4">
      <div class="d-flex h-full flex-column autoscroll-y">
        <UploadAddressesOverview
          v-if="tab == 0"
          @change="tab = 1"
        />
        <UploadAddressesList
          v-else-if="tab == 1"
          @change="tab = 0"
        />
      </div>
      <v-divider />
      <v-btn
        x-large
        @click="tab = tab == 0 ? 1 : 0"
      >
        {{ tab == 0 ? 'View Full List >' : '&lt; View Overview' }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {} from 'lodash';
import UploadAddressesOverview from '@/components/print/checkout/snailblast/upload/addresses/Overview';
import UploadAddressesList from '@/components/print/checkout/snailblast/upload/addresses/List';
import UploadChange from '@/components/print/checkout/snailblast/upload/Change';
import { APP_EVENTS, DRAWERS, ANALYTICS_EVENTS } from '@/utils/constants';
import { mdiCheckboxMarked } from '@mdi/js';
export default {
  name: 'SnailblastUploadAddresses',
  components: {
    UploadAddressesOverview,
    UploadAddressesList,
    UploadChange
  },
  data: () => ({
    loading: false,
    checkIcon: mdiCheckboxMarked,
    tab: 0
  }),
  computed: {
    campaign: {
      get() {
        return this.$store.getters['snailblast/campaign/edit_campaign'];
      },
      set(val) {
        this.$store.dispatch('snailblast/campaign/setEditCampaign', val);
      }
    },
    saving: {
      get() {
        return this.$store.getters['snailblast/campaign/saving'];
      },
      set(val) {
        this.$store.dispatch('snailblast/campaign/setSaving', val);
      }
    },
    ...mapGetters({
      addresses: 'snailblast/campaign/addresses',
      price: 'printcart/price',
      address_count: 'snailblast/campaign/address_count',
      analytics_data: 'snailblast/campaign/analytics_data'
    })
  },
  watch: {
    tab(val, oldVal) {
      if (val !== oldVal) {
        const screen =
          val === 0
            ? ANALYTICS_EVENTS.MAILING_SERVICE.SCREENS.UPLOAD_OVERVIEW
            : ANALYTICS_EVENTS.MAILING_SERVICE.SCREENS.UPLOAD_VIEW_LIST;
        this.$mixpanel.trackEvent(
          ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
          null,
          Object.assign(this.analytics_data, { screen })
        );
      }
    }
  },
  mounted() {
    this.$mixpanel.trackEvent(
      ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
      null,
      Object.assign(this.analytics_data, {
        screen: ANALYTICS_EVENTS.MAILING_SERVICE.SCREENS.UPLOAD_OVERVIEW
      })
    );
    this.updatePrice();
  },
  methods: {
    updatePrice() {
      this.$eventBus.$emit(APP_EVENTS.GET_EDIT_ITEM_PRICE, { source: 'upload' });
    },
    save() {
      this.$mixpanel.trackEvent(
        ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
        ANALYTICS_EVENTS.MAILING_SERVICE.ACTIONS.SAVE_COMPLETE,
        Object.assign(this.analytics_data, {
          mailing_service: 'upload',
          audience_size: this.address_count,
          price: this.price
        })
      );
      this.$store.dispatch('snailblast/campaign/trackSaveEvent', this.campaign);
      this.saving = true;
      this.$store
        .dispatch('snailblast/campaign/refresh', this.campaign.id)
        .then(() => {
          this.$store.commit('drawers/CLOSE_DRAWER', DRAWERS.SNAILBLAST_AUDIENCE);
        })
        .catch((error) => {
          this.logError(error, 'Error refreshing campaign');
        })
        .finally(() => {
          this.saving = false;
        });
    }
  }
};
</script>
