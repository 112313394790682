<template>
  <div>
    <FieldHeader
      :field="field"
      @back="$emit('back')"
      @save="applyFilters"
    />
    <v-divider />
    <div class="pa-4">
      <v-form
        ref="form"
        v-model="valid"
      >
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="start"
              dense
              outlined
              :rules="[$rules.required]"
              hide-details="auto"
              label="Start Year"
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="end"
              dense
              outlined
              :rules="[$rules.required, rangeRule]"
              hide-details="auto"
              label="End Year"
            />
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FieldHeader from '@/components/print/checkout/snailblast/listpurchase/filters/field/Header';
export default {
  name: 'SnailblastListPurchaseFiltersYearRange',
  components: { FieldHeader },
  props: { field: { type: Object, default: () => {} } },
  data() {
    return {
      start: null,
      end: null,
      valid: false
    };
  },
  computed: {
    ...mapGetters({
      filters: 'snailblast/campaign/list_purchase_filters'
    })
  },
  created() {
    const field = this.filters.find((x) => x.field.id === this.field.id);
    if (field) {
      const values = field.value.split('-');
      if (values.length === 2) {
        this.start = parseInt(values[0]);
        this.end = parseInt(values[1]);
      }
    }
  },
  methods: {
    rangeRule() {
      return !this.start || !this.end || this.start <= this.end || 'Start must be less than end';
    },
    applyFilters() {
      if (this.$refs.form.validate()) {
        const newFilters = this.filters.filter((x) => x.field.id !== this.field.id);
        newFilters.push({
          field: this.field,
          value: `${this.start}-${this.end}`,
          text: `${this.start}-${this.end}`
        });
        this.$emit('setfilters', newFilters);
      }
    }
  }
};
</script>
