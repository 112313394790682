<template>
  <div>
    <v-sheet class="rounded border bg-light bordered border-light pa-4 mb-5">
      <div
        v-if="certificate.expired"
        class="body-2"
      >
        This certificate has epxired. Please replace this certificate with a current one.
      </div>
      <div
        v-else
        class="body-2"
      >
        <div>
          <v-icon :color="certificate.color">
            {{ circleIcon }}
          </v-icon>
          <span class="font-weight-bold">{{ certificate.status }}</span>
        </div>
        <div class="ml-2">
          {{ statusMessage }}
        </div>
      </div>
    </v-sheet>

    <CertificateUploadButton
      buttonText="Replace Certificate"
      :certificateId="certificate.id"
      @deleted="$emit('home')"
    />

    <v-text-field
      v-model="edit_certificate.name"
      outlined
      label="Cerficiate Name"
      placeholder="Certificate Name"
      persistent-placeholder
      maxlength="100"
      hide-details="auto"
      class="white mt-5"
    />

    <v-textarea
      v-model="edit_certificate.userNote"
      label="Please describe why you are exempt from sales tax"
      persistent-placeholder
      outlined
      class="body-2 mt-5"
      maxlength="500"
      hide-details="auto"
    />

    <Portal :to="`drawerAppend-${drawerId}`">
      <v-row class="border border-t border-light pa-2 ma-0">
        <v-col cols="12">
          <v-btn
            :loading="saving"
            x-large
            block
            color="black"
            class="white--text"
            elevation="0"
            @click="save"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </Portal>
  </div>
</template>

<script>
import CertificateUploadButton from './CertificateUploadButton';
import { mdiCircleMedium } from '@mdi/js';
import { EXEMPTION_CERTIFICATE_STATUS, DRAWERS } from '../../../utils/constants';
import { cloneDeep } from 'lodash';

export default {
  name: 'CertificateDetailsView',

  components: {
    CertificateUploadButton
  },

  props: {
    id: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      drawerId: DRAWERS.EXEMPTION_CERTIFICATES_DRAWER,
      circleIcon: mdiCircleMedium,
      certificate: {},
      edit_certificate: {},
      saving: false
    };
  },

  computed: {
    statusMessage() {
      var msg = '';
      if (this.certificate.statusId === EXEMPTION_CERTIFICATE_STATUS.APPROVED) {
        msg =
          'This tax exemption certificate has been reviewed and aproved. You are exempt from paying sales tax on your orders';
      } else if (this.certificate.statusId === EXEMPTION_CERTIFICATE_STATUS.UNDER_REVIEW) {
        msg =
          'This tax exemption is under review. You’re exempt from paying sales tax and can order unless we find any issues.';
      } else {
        msg = this.certificate.rejectionNote;
      }
      return msg;
    }
  },

  mounted() {
    this.certificate = this.$store.getters['user/taxExemptCertificates'].find(
      (x) => x.id === this.id
    );
    this.edit_certificate = cloneDeep(this.certificate);
  },

  methods: {
    async save() {
      this.saving = true;
      await this.$store
        .dispatch('user/updateExemptionCertificate', this.edit_certificate)
        .finally(() => {
          this.saving = false;
        });
    }
  }
};
</script>

<style scoped></style>
