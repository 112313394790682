<template>
  <v-menu>
    <template #activator="{ on, attrs }">
      <v-btn
        small
        rounded
        outlined
        class="bg-white ml-2 px-0"
        style="min-width: 35px"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon small>
          {{ layerIcon }}
        </v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <template v-for="(item, j) in styles">
        <v-list-item
          :key="j"
          @click="select(item.id)"
        >
          <v-list-item-action>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { ENUMS } from '@/utils/constants';
import { mdiLayersOutline } from '@mdi/js';
export default {
  props: {},
  data() {
    return {
      styles: [
        { id: ENUMS.SNAILBLAST.MAP_STYLES.STREETS, name: 'Classic' },
        { id: ENUMS.SNAILBLAST.MAP_STYLES.SATELLITES, name: 'Satellite' }
      ],
      layerIcon: mdiLayersOutline
    };
  },
  methods: {
    select(id) {
      this.$emit('select', id);
    }
  }
};
</script>

<style lang="scss" scoped>
.float {
  position: absolute;
  z-index: 1;
}
</style>
