<template>
  <div
    v-if="addresses"
    class="h-full d-flex flex-column"
  >
    <div class="bg-light text-center">
      <v-img
        class="mx-auto mt-8"
        width="130"
        src="https://res.cloudinary.com/mycreativeshop-blog/image/upload/v1716990281/sb-audience-illustration.svg"
      />
    </div>
    <v-divider />
    <div class="pa-4 font-weight-bold d-flex justify-space-between">
      <span> Status </span>
      <span> Addresses </span>
    </div>
    <v-divider />
    <div class="pa-4">
      <v-row
        v-for="(item, i) in rows"
        :key="i"
        dense
      >
        <v-col cols="auto">
          <v-icon
            small
            :color="item.iconColor"
            class="mr-1"
          >
            {{ item.icon }}
          </v-icon>
          {{ item.label }}
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          {{ item.count | separator }}
        </v-col>
      </v-row>
      <v-row
        dense
        class="mt-2"
      >
        <v-col
          v-if="addresses.duplicates"
          cols="12"
        >
          <v-btn
            block
            outlined
            :loading="saving"
            @click="deleteDuplicates"
          >
            Merge {{ addresses.duplicates }} Duplicates
          </v-btn>
        </v-col>
        <v-col
          v-if="addresses.errors && addresses.errors.length"
          cols="12"
        >
          <v-btn
            block
            outlined
            :loading="saving"
            @click="deleteErrors"
          >
            Remove {{ addresses.errors.length }} Addresses with Errors
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn
            block
            outlined
            @click="showAddressForm"
          >
            Manually Add Address
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiCardAccountDetails } from '@mdi/js';
import { mapGetters } from 'vuex';
import { APP_EVENTS, DRAWERS } from '@/utils/constants';
export default {
  name: 'SnailblastUploadAddressesOverview',

  data: () => ({
    saving: false
  }),

  computed: {
    rows() {
      const list = [];

      list.push({
        icon: mdiCardAccountDetails,
        iconColor: this.addresses.duplicates ? 'orange' : 'black',
        label: 'Duplicates',
        count: this.addresses.duplicates
      });

      list.push({
        icon: mdiCardAccountDetails,
        iconColor: this.addresses.errors && this.addresses.errors.length ? 'orange' : 'black',
        label: 'Errors',
        count: this.addresses.errors?.length
      });

      list.push({
        icon: mdiCardAccountDetails,
        iconColor: 'black',
        label: 'Approved',
        count: this.addresses.approvedCount
      });

      return list;
    },
    ...mapGetters({
      addresses: 'snailblast/campaign/addresses',
      campaign: 'snailblast/campaign/edit_campaign'
    })
  },

  methods: {
    deleteDuplicates() {
      this.saving = true;
      this.$store
        .dispatch('snailblast/campaign/deleteDuplicates', this.campaign.id)
        .then(() => {
          this.updatePrice();
        })
        .catch((error) => {
          this.logError(error, 'Error deleting duplicate addresses');
        })
        .finally(() => {
          this.saving = false;
        });
    },
    deleteErrors() {
      this.saving = true;
      this.$store
        .dispatch('snailblast/campaign/deleteErrors', this.campaign.id)
        .then(() => {
          this.updatePrice();
        })
        .catch((error) => {
          this.logError(error, 'Error deleting invalid addresses');
        })
        .finally(() => {
          this.saving = false;
        });
    },
    updatePrice() {
      this.$eventBus.$emit(APP_EVENTS.GET_EDIT_ITEM_PRICE, { source: 'uploadOverview' });
    },
    showAddressForm() {
      this.$store.commit('drawers/SET_DATA', {
        drawerId: DRAWERS.SNAILBLAST_UPLOAD_ADDRESS_EDIT,
        data: {}
      });
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.SNAILBLAST_UPLOAD_ADDRESS_EDIT);
    }
  }
};
</script>
