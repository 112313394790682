<template>
  <div class="bg-white bordered rounded-lg border-thin px-2 pb-1">
    <v-icon
      class="hover-pointer mr-1"
      style="margin-top: 5px"
      small
      @click="$emit('zoom', false)"
    >
      {{ minusIcon }}
    </v-icon>
    <v-icon
      class="hover-pointer"
      style="margin-top: 5px"
      small
      @click="$emit('zoom', true)"
    >
      {{ plusIcon }}
    </v-icon>
  </div>
</template>

<script>
import { mdiPlus, mdiMinus } from '@mdi/js';
export default {
  name: 'MapZoom',
  data() {
    return {
      plusIcon: mdiPlus,
      minusIcon: mdiMinus
    };
  }
};
</script>
