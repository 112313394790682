<template>
  <v-autocomplete
    ref="search"
    v-model="address"
    :items="addresses"
    :loading="loading_addresses"
    :search-input.sync="search"
    item-text="place_name"
    item-value="place_name"
    :prepend-inner-icon="searchIcon"
    append-icon=""
    hide-no-data
    outlined
    rounded
    solo
    flat
    hide-details
    return-object
    :filter="filterAutocomplete"
    placeholder="Search by location"
    auto-select-first
    style="z-index: 10000"
    :menu-props="{ maxWidth: '400px' }"
    @change="selectAddress"
  >
    <template #append>
      <RadiusSelect
        v-if="is_eddm"
        v-model="radius"
        @apply="apply"
        @open="$refs.search.blur()"
      />
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'debounce';
import { mdiMagnify, mdiMenuDown, mdiClose } from '@mdi/js';
import snailblast from '@/utils/mixins/snailblast';
import RadiusSelect from '@/components/print/checkout/snailblast/RadiusSelect';
export default {
  components: { RadiusSelect },
  mixins: [snailblast],
  data() {
    return {
      searchIcon: mdiMagnify,
      dropdownIcon: mdiMenuDown,
      closeIcon: mdiClose,
      loading_addresses: false,
      addresses: [],
      address: {},
      search: '',
      radius: 5,
      ignoreSearchChange: false
    };
  },
  computed: {
    ...mapGetters({
      map_center: 'snailblast/routes/map_center',
      map_radius: 'snailblast/routes/map_radius',
      is_eddm: 'snailblast/campaign/is_eddm'
    })
  },
  watch: {
    search(val, oldVal) {
      if (this.ignoreSearchChange) {
        this.ignoreSearchChange = false;
        return;
      }
      if (!val || val === oldVal) {
        return;
      }
      if (val.length < 3) {
        this.addresses = [];
        return;
      }
      debounce(this.searchAddresses, 500)(val, this);
    },
    map_center(val, oldVal) {
      if (this.setTextFromMapCenter) {
        this.ignoreSearchChange = true;
        this.search = val.text;
      }
    }
  },
  created() {
    this.radius = this.map_radius;
  },
  mounted() {},
  methods: {
    apply() {
      this.$store.dispatch('snailblast/routes/setMapRadius', this.radius);
      if (this.map_center) {
        this.selectAddress({ center: [this.map_center.lon, this.map_center.lat] });
      }
    },
    async searchAddresses(val) {
      if (!val) {
        this.addresses = [];
        this.address = {};
      }
      if (this.loading_addresses) {
        return;
      }

      this.loading_addresses = true;

      this.addresses = await this.getMapboxPlaces(val);

      this.loading_addresses = false;
    },
    selectAddress(val) {
      if (!val) {
        return;
      }
      this.$emit('search', {
        lat: val.center[1],
        lon: val.center[0],
        radius: this.radius,
        text: val.place_name,
        mapbox: val
      });
      this.$refs.search.blur();
    },
    filterAutocomplete(item, queryText, itemText) {
      return true;
    },
    max(val) {
      const max = 10;
      return parseFloat(val) <= max || `Radius must be less than ${max}`;
    },
    min(val) {
      const min = 0.01;
      return val >= min || `Radius must be greater than ${min}`;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .v-input__append-outer {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
::v-deep .v-input__append-inner {
  margin-top: auto !important;
  margin-bottom: auto !important;
}
</style>
