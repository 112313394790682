<template>
  <v-btn
    small
    rounded
    outlined
    class="bg-warning ml-2"
    @click="expand"
  >
    <v-icon
      class="mr-1"
      small
    >
      {{ expanded ? collapseIcon : expandIcon }}
    </v-icon>
    {{ expanded ? 'Collapse' : 'Expand' }}
  </v-btn>
</template>

<script>
import { mdiArrowExpandAll, mdiArrowCollapseAll } from '@mdi/js';
import { ANALYTICS_EVENTS } from '@/utils/constants';
import { mapGetters } from 'vuex';
export default {
  name: 'MapExpand',
  props: { expanded: { type: Boolean, default: false } },
  data() {
    return {
      expandIcon: mdiArrowExpandAll,
      collapseIcon: mdiArrowCollapseAll
    };
  },
  computed: {
    ...mapGetters({
      is_eddm: 'snailblast/campaign/is_eddm',
      is_list_purchase: 'snailblast/campaign/is_list_purchase',
      analytics_data: 'snailblast/campaign/analytics_data'
    })
  },
  methods: {
    expand() {
      if (!this.expanded) {
        this.$mixpanel.trackEvent(
          ANALYTICS_EVENTS.MAILING_SERVICE.NAME,
          ANALYTICS_EVENTS.MAILING_SERVICE.ACTIONS.MAP_EXPAND,
          this.analytics_data
        );
      }
      this.$emit('expand');
    }
  }
};
</script>
