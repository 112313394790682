<template>
  <div class="d-flex w-full h-full flex-column align-center justify-center">
    <v-progress-circular
      :size="125"
      color="brand"
      indeterminate
    />
  </div>
</template>

<script>
export default {
  name: 'AppLoading',

  components: {},

  mixins: [],

  data() {
    return {};
  },

  methods: {}
};
</script>

<style></style>
