<template>
  <Drawer
    :id="drawerId"
    :title="title"
    :loading="editLoading || loadingInitialData"
    :iframed="iframed"
    preloadContent
  >
    <!-- Custom Back Button (for navigating between print cart screens) -->
    <template
      v-if="showBack"
      #customBack
    >
      <v-btn
        icon
        @click="back"
      >
        <v-icon>{{ backIcon }}</v-icon>
      </v-btn>
    </template>

    <!-- Shopping cart icon in the header of the drawer -->
    <template
      v-if="cart_lines.length && !iframed"
      #cart
    >
      <CartButton @click.native.stop="showEdit = false" />
    </template>

    <!-- Show the alert promo bar on iframed checkouts -->
    <div
      v-if="iframed"
      class="relative"
    >
      <AlertBar />
    </div>

    <!-- The Stepper. Details -> Cart -> Checkout. We show this whenever they aren't selecting from their list of projects -->
    <v-sheet class="px-6 py-4">
      <v-stepper
        v-model="step"
        alt-labels
        flat
        tile
      >
        <v-stepper-header>
          <v-stepper-step
            step="1"
            :complete="step > 1"
            color="black"
            class="pr-2"
          >
            Details
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            step="2"
            :complete="step > 2"
            color="black"
            class="px-5"
            :class="{ 'hover-pointer': enableCartStepperClick }"
            v-on="
              enableCartStepperClick
                ? {
                    click: () => {
                      setActiveScreen(cartScreen);
                    }
                  }
                : {}
            "
          >
            Cart
          </v-stepper-step>
          <v-divider />
          <v-stepper-step
            step="3"
            :complete="step > 3"
            color="black"
          >
            Checkout
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </v-sheet>

    <!-- Show the project thumbnail + proof-->
    <ProjectDetails
      v-if="(showEditScreen || editLoading) && project.id"
      :iframed="iframed"
      @doEditItem="editItem($event)"
      @refreshPrice="$refs.edit.getPrice()"
    />

    <PrintEditItem
      v-show="showEditScreen"
      ref="edit"
      :drawerId="drawerId"
      :hideFooter="!showEditScreen"
      :iframed="iframed"
      :calculateSalesTax="showCheckoutScreen"
      @save="setActiveScreen(cartScreen)"
      @selectProject="setActiveScreen(editScreen)"
      @loaded="editorLoaded"
    />
    <PrintCart
      v-if="showCartScreen"
      :drawerId="drawerId"
      :iframed="iframed"
      @edit="editItem"
      @edit-shipping="editItem($event, true)"
      @add="addAnother"
      @checkout="setActiveScreen(checkoutScreen)"
      @empty="onCartEmpty"
    />
    <PrintCheckout
      v-else-if="showCheckoutScreen"
      :drawerId="drawerId"
      @back="showCheckout = false"
      @confirm="onConfirm"
      @cart="setActiveScreen(cartScreen)"
    />
    <PrintConfirmation
      v-else-if="showConfirmationScreen"
      :orderId="orderId"
      :iframed="iframed"
    />
  </Drawer>
</template>

<script>
import {
  ANALYTICS_EVENTS,
  APP_EVENTS,
  DRAWERS,
  PRINT_CHECKOUT_SCREENS
} from '../../../utils/constants';
import Drawer from '../../../components/layout/Drawer';
import CartButton from '../../../components/header/CartButton';
import ProjectDetails from '../../../components/print/checkout/ProjectDetails';
import PrintEditItem from '@/components/print/checkout/edititem/Index';
import PrintConfirmation from '@/components/print/checkout/Confirmation';
import PrintCheckout from '@/components/print/checkout/Checkout';
import PrintCart from '@/components/print/checkout/Cart';
import AlertBar from '../../layout/AlertBar';
import { mapGetters } from 'vuex';
import { mdiKeyboardBackspace, mdiClose, mdiCheckCircle, mdiPencilOutline } from '@mdi/js';

export default {
  name: 'PrintCheckoutDrawer',

  components: {
    Drawer,
    CartButton,
    ProjectDetails,
    PrintEditItem,
    PrintConfirmation,
    PrintCheckout,
    PrintCart,
    AlertBar
  },

  props: {
    iframed: Boolean
  },

  data() {
    return {
      closeIcon: mdiClose,
      backIcon: mdiKeyboardBackspace,
      checkIcon: mdiCheckCircle,
      editIcon: mdiPencilOutline,
      drawerId: DRAWERS.PRINT_CHECKOUT,
      editScreen: PRINT_CHECKOUT_SCREENS.EDIT,
      activeScreen: PRINT_CHECKOUT_SCREENS.EDIT,
      cartScreen: PRINT_CHECKOUT_SCREENS.CART,
      checkoutScreen: PRINT_CHECKOUT_SCREENS.CHECKOUT,
      confirmationScreen: PRINT_CHECKOUT_SCREENS.CONFIRMATION,
      saving: false,
      valid: true,
      loadingInitialData: false,
      editLoading: false,
      orderId: 0
    };
  },

  computed: {
    step() {
      if (this.showEditScreen) {
        return 1;
      } else if (this.showCartScreen) {
        return 2;
      } else if (this.showCheckoutScreen) {
        return 3;
      } else if (this.showConfirmationScreen) {
        return 4;
      }
      return 1;
    },

    title() {
      if (this.showEditScreen) {
        if (this.edit_item.categoryId) {
          return `${
            this.categories.filter((x) => x.id === this.edit_item.categoryId).map((x) => x.name)[0]
          } Prints`;
        }
        return 'Select Print Options';
      } else if (this.showCheckoutScreen) {
        return 'Payment';
      } else if (this.showConfirmationScreen) {
        return 'Thanks for your order!';
      } else {
        var s = this.cart_lines.length > 1 ? 's' : '';
        return `Your Cart (${this.cart_lines.length} item${s})`;
      }
    },

    showBack() {
      if (this.iframed) {
        if (this.showCheckoutScreen) return true;
        return false;
      }
      if (this.showCheckoutScreen) return true;
      if (this.showEditScreen && this.cart_lines.length) return true;
      return false;
    },

    showCartScreen() {
      return this.activeScreen === PRINT_CHECKOUT_SCREENS.CART;
    },

    showEditScreen() {
      return this.activeScreen === PRINT_CHECKOUT_SCREENS.EDIT;
    },

    showCheckoutScreen() {
      return this.activeScreen === PRINT_CHECKOUT_SCREENS.CHECKOUT;
    },

    showConfirmationScreen() {
      return this.activeScreen === PRINT_CHECKOUT_SCREENS.CONFIRMATION;
    },

    enableCartStepperClick() {
      if (!this.cart_lines.length) return false;
      return !(this.showConfirmationScreen || this.showCartScreen);
    },

    ...mapGetters({
      products: 'printcart/products',
      delivery: 'printcart/delivery',
      addresses: 'user/addresses',
      cart: 'printcart/cart',
      cart_lines: 'printcart/cart_lines',
      edit_item: 'printcart/edit_item',
      initialData: 'drawers/data',
      drawerOpenedAt: 'drawers/lastOpenedAt',
      categories: 'templates/printableCategories',
      project: 'printcart/project',
      getProject: 'projects/project'
    })
  },

  watch: {
    drawerOpenedAt() {
      if (this.loadingInitialData) return;
      if (this.drawerId === this.$store.getters['drawers/topDrawer']?.id) {
        this.loadData();
        this.$store.dispatch('printcart/trackView', this.initialData(this.drawerId).projectId);
      }
    },

    activeScreen() {
      var contentEl = document
        .getElementById(this.drawerId)
        .closest('.v-navigation-drawer')
        .querySelector('.v-navigation-drawer__content');
      if (contentEl) contentEl.scrollTo({ top: 0, behavior: 'smooth' });
    }
  },

  mounted() {
    this.$eventBus.$on(APP_EVENTS.REFRESH_PRINT_DRAWER, () => {
      this.selectInitialScreen();
    });
  },

  methods: {
    selectInitialScreen() {
      const initialData = this.initialData(this.drawerId) || {};
      var screen = initialData.screen;
      if (initialData.projectId) {
        if (
          this.$store.getters['printcart/isProjectInCart'](initialData.projectId) &&
          this.getProject(initialData.projectId).printApproved
        ) {
          screen = PRINT_CHECKOUT_SCREENS.CART;
        } else {
          screen = PRINT_CHECKOUT_SCREENS.EDIT;
        }
      }

      if (screen === PRINT_CHECKOUT_SCREENS.EDIT && initialData.projectId) {
        this.selectProject(this.getProject(initialData.projectId));
        return;
      } else if (!this.cart_lines.length && !this.iframed) {
        this.editItem({ categoryId: initialData.categoryId });
        return;
      }

      if (initialData) {
        if (initialData.categoryId) {
          this.editItem({ categoryId: initialData.categoryId });
        }
        this.$store.commit('drawers/CLEAR_DATA', this.drawerId);
      }
      this.setActiveScreen(screen);
    },

    editorLoaded() {
      this.showEdit = true;
      console.log('edit loading is false');
      this.editLoading = false;
    },

    setActiveScreen(val) {
      if (val) this.activeScreen = val;
    },

    back() {
      if (this.showCheckoutScreen || (this.showEdit && this.cart_lines.length)) {
        this.setActiveScreen(PRINT_CHECKOUT_SCREENS.CART);
      }
    },

    loadData(project) {
      this.loadingInitialData = true;
      this.orderId = 0;
      Promise.all([
        this.$store.dispatch('printcart/setEditItem', {}),
        this.$store.dispatch('printcart/getCart'),
        this.$store.dispatch('projects/getDesigns'),
        this.$store.dispatch('projects/getUploads')
      ])
        .then(() => {
          if (project) {
            this.selectProject(project);
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(project);
          this.$mixpanel.trackEvent(
            ANALYTICS_EVENTS.PRINT.NAME,
            ANALYTICS_EVENTS.PRINT.ACTIONS.CART_ERROR,
            { message: 'Error loading checkout. ' + error.message }
          );
          this.$root.$alert('Error Occurred', 'An error occurred while loading print checkout.');
        })
        .finally(() => {
          this.selectInitialScreen();
          this.loadingInitialData = false;
        });
    },

    addAnother() {
      let categoryId = null;
      if (this.cart_lines.length) {
        categoryId = this.cart_lines.map((x) => x.categoryId)[this.cart_lines.length - 1];
      }
      this.editItem({ categoryId });
    },

    selectProject(project) {
      this.editItem({ project });
    },

    editItem(item, showShipping) {
      this.setActiveScreen(PRINT_CHECKOUT_SCREENS.EDIT);
      this.$store.dispatch('printcart/setEditItem', item).then(() => {
        this.editLoading = true;
        this.$refs.edit.loadData(showShipping);
      });
    },

    onConfirm(orderId) {
      this.$store.commit('user/UPDATE_HAS_ORDERED', true);
      this.orderId = orderId;
      this.setActiveScreen(this.confirmationScreen);
    },

    onCartEmpty() {
      this.selectInitialScreen();
    }
  }
};
</script>

<style scoped>
.preview-background {
  background: transparent linear-gradient(180deg, #ffffff 0%, #ececefd9 100%) 0% 0% no-repeat
    padding-box;
  border: ;
}
.v-stepper__step {
  flex-basis: auto !important;
  padding: 0px;
  .v-stepper__label {
    font-size: 10px;
    font-weight: 600;
  }
}
.v-stepper__header .v-divider {
  margin: 10px 0 0 0 !important;
  border-top: dotted 3px black !important;
}
>>> .page-preview {
  border: solid 1.5px;
  border-radius: 5px;
  max-width: 100px;
  max-height: 35px;
}
</style>
