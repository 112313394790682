<template>
  <div>
    <v-menu
      open-on-hover
      :top="top"
      :bottm="bottom"
      :left="left"
      :right="right"
      :offset-x="offsetX"
      :offset-y="offsetY"
    >
      <template #activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            v-if="icon && content && !appendIcon"
            :size="iconSize"
            :class="{ 'mr-2': text !== '' }"
          >
            {{ icon }}
          </v-icon>

          <span
            :style="dashedUnderlineStyle"
            v-html="text"
          ></span>

          <v-icon
            v-if="icon && content && appendIcon"
            :size="iconSize"
            class="ml-1"
          >
            {{ icon }}
          </v-icon>
        </div>
      </template>
      <v-card
        class="py-2 px-3"
        :style="{ width: width }"
      >
        <div
          class="body-2"
          v-html="content"
        />
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mdiHelpCircleOutline } from '@mdi/js';

export default {
  name: 'Tooltip',

  props: {
    icon: {
      type: String,
      default: mdiHelpCircleOutline
    },
    iconSize: {
      type: Number,
      default: 16
    },
    text: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    align: {
      type: String,
      default: 'top' // values: top, left, right, bottom
    },
    offset: {
      type: String,
      default: '' // values:  x, y
    },
    maxWidth: {
      type: Number,
      default: 0
    },
    appendIcon: Boolean
  },

  data() {
    return {};
  },

  computed: {
    offsetX() {
      return this.offset === 'x';
    },
    offsetY() {
      return this.offset === 'y';
    },
    top() {
      return this.align === 'top';
    },
    bottom() {
      return this.align === 'bottom';
    },
    left() {
      return this.align === 'left';
    },
    right() {
      return this.align === 'right';
    },
    width() {
      return this.maxWidth ? this.maxWidth + 'px' : 'auto';
    },
    dashedUnderlineStyle() {
      var value = 'dashed 1px gray';
      if (this.appendIcon && this.text) value = 'none';
      return { 'border-bottom': value };
    }
  }
};
</script>

<style scoped></style>
