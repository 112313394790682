<template>
  <Drawer
    :id="drawerId"
    title="Add Card"
    padded
    @close="show = false"
  >
    <PaymentEntryForm
      mode="setup"
      :showCards="false"
      :returnUrl="returnUrl"
      @success="onSuccess"
    />
  </Drawer>
</template>

<script>
import { APP_EVENTS, DRAWERS, ROUTES } from '../../utils/constants';
import Drawer from '../../components/layout/Drawer';
import PaymentEntryForm from './PaymentEntryForm';
import copyObject from '../../utils/copyObject';

export default {
  name: 'AddCardDrawer',

  components: {
    Drawer,
    PaymentEntryForm
  },

  data() {
    return {
      drawerId: DRAWERS.ADD_CARD_DRAWER
    };
  },

  computed: {
    returnUrl() {
      return this.$store.state.config.dashboardUrl + ROUTES.CARDS.PATH;
    }
  },

  methods: {
    async onSuccess() {
      var previousCardIds = copyObject(this.$store.state.cards.items.map((x) => x.id));
      await this.$store.dispatch('cards/getAll');
      var currentCardIds = this.$store.state.cards.items.map((x) => x.id);
      var newCardIds = currentCardIds.filter((x) => !previousCardIds.includes(x));
      this.$store.commit('drawers/CLOSE_DRAWER', DRAWERS.ADD_CARD_DRAWER);
      if (newCardIds.length) {
        this.$eventBus.$emit(APP_EVENTS.SET_PAYMENT_METHOD, newCardIds[0]);
      }
    }
  }
};
</script>

<style></style>
