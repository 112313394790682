<template>
  <Drawer
    :id="drawerId"
    preloadContent
    height="75%"
    bottom
    closeGoesBack
    hideBack
    :loading="loading"
  >
    <template #title>
      {{ addressId ? 'Edit Address' : 'Add Address' }}
    </template>
    <div class="pa-4">
      <AddressForm
        v-if="open"
        :id="addressId"
        :drawer-id="drawerId"
        address-getter="snailblast/campaign/address_list"
        force-usa
        hide-default
        save-action="snailblast/campaign/saveAddress"
        delete-action="snailblast/campaign/deleteAddress"
        @save="close"
      />
    </div>
  </Drawer>
</template>

<script>
import { DRAWERS } from '@/utils/constants';
import Drawer from '@/components/layout/Drawer';
import { mapGetters } from 'vuex';
import {} from '@mdi/js';
import {} from 'lodash';
import AddressForm from '@/components/print/checkout/addresses/Form.vue';

export default {
  name: 'SnailblastUploadAddressesEditDrawer',

  components: {
    Drawer,
    AddressForm
  },

  data() {
    return {
      loading: false,
      drawerId: DRAWERS.SNAILBLAST_UPLOAD_ADDRESS_EDIT,
      addressId: null
    };
  },

  computed: {
    open() {
      return this.drawerId === this.topDrawer?.id;
    },
    ...mapGetters({
      initialData: 'drawers/data',
      drawerOpenedAt: 'drawers/lastOpenedAt',
      topDrawer: 'drawers/topDrawer'
    })
  },

  watch: {
    drawerOpenedAt() {
      if (this.open) {
        var drawerData = this.initialData(this.drawerId);
        this.addressId = drawerData.addressId;
      }
    }
  },

  methods: {
    close() {
      this.$store.commit('drawers/CLOSE_DRAWER', this.drawerId);
    }
  }
};
</script>

<style scoped>
nav {
  top: inherit !important;
  bottom: 0 !important;
}
.v-navigation-drawer--bottom.v-navigation-drawer--is-mobile {
  max-height: 75% !important;
}
</style>
