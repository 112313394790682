<template>
  <div>
    <FieldHeader
      :field="field"
      @save="applyFilters"
      @back="$emit('back')"
    />
    <v-divider />
    <v-radio-group
      v-model="selected"
      class="mx-4"
    >
      <v-radio
        label="True"
        :value="true"
        color="black"
      />
      <v-radio
        label="False"
        :value="false"
        color="black"
      />
    </v-radio-group>
  </div>
</template>

<script>
import { mdiMagnify } from '@mdi/js';
import { mapGetters } from 'vuex';
import FieldHeader from '@/components/print/checkout/snailblast/listpurchase/filters/field/Header';
export default {
  name: 'SnailblastListPurchaseFiltersBool',
  components: { FieldHeader },
  props: { field: { type: Object, default: () => {} } },

  data() {
    return {
      searchIcon: mdiMagnify,
      search: '',
      loading: false,
      selected: false
    };
  },
  computed: {
    ...mapGetters({
      lookup_field: 'snailblast/lookup/list_purchase_field',
      filters: 'snailblast/campaign/list_purchase_filters'
    })
  },
  created() {
    if (!this.field.selectItems || !this.field.selectItems.length) {
      this.loading = true;
      this.$store
        .dispatch('snailblast/lookup/getListPurchaseFieldSelectItems', this.field.id)
        .catch((error) => {
          this.logError(error, 'Error getting select items');
        })
        .finally(() => {
          this.loading = false;
        });
    }
    this.selected = !!this.filters.filter((x) => x.field.id === this.field.id).length;
  },
  methods: {
    applyFilters() {
      const list = [];
      if (this.selected) {
        list.push({ field: this.field, value: '1', text: 'Yes' });
      }
      this.addFilters(list);
    },
    addFilters(filters) {
      let newFilters = this.filters.filter((x) => x.field.id !== this.field.id);
      newFilters = newFilters.concat(filters);
      this.$emit('setfilters', newFilters);
    }
  }
};
</script>
