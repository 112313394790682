<template>
  <div class="d-flex h-full flex-column autoscroll-y">
    <v-text-field
      v-model="search"
      class="mx-4"
      :prepend-icon="searchIcon"
      placeholder="Search filter"
      label="Search filter"
      flat
      solo
      hide-details
    />
    <v-divider />
    <div
      class="d-flex h-full flex-column autoscroll-y"
      style="overflow-x: hidden"
    >
      <v-virtual-scroll
        bench="20"
        :items="items"
        height="100%"
        item-height="90"
      >
        <template #default="{ item }">
          <v-list-item :key="item.id">
            <v-list-item-content>
              <v-list-item-title class="caption">
                <div v-if="item.fullName">
                  {{ item.fullName }}
                </div>
                <div v-if="item.company">
                  {{ item.company }}
                </div>
                <div>{{ item.line1 }}</div>
                <div v-if="item.line2">
                  {{ item.line2 }}
                </div>
                <div>
                  {{ item.city }}, {{ state_abbr(item.stateProvinceId) }} {{ item.postalCode }}
                </div>
                <div :class="`${icon(item).color}--text mt-1`">
                  <v-icon
                    small
                    :color="icon(item).color"
                    class="mr-1"
                  >
                    {{ icon(item).icon }}
                  </v-icon>
                  {{ icon(item).label }}
                </div>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action class="ml-2">
              <v-btn
                icon
                class="mr-2"
                small
                :loading="deleting"
                @click="deleteAddress(item.id)"
              >
                <v-icon small>
                  {{ deleteIcon }}
                </v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action class="ml-2">
              <v-btn
                icon
                small
                @click="editAddress(item.id)"
              >
                <v-icon small>
                  {{ editIcon }}
                </v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider />
        </template>
      </v-virtual-scroll>
    </div>
  </div>
</template>

<script>
import { mdiMagnify, mdiDelete, mdiPencil, mdiCheckboxMarked, mdiAlert } from '@mdi/js';
import { mapGetters } from 'vuex';
import { DRAWERS } from '@/utils/constants';
import { orderBy } from 'lodash';
export default {
  name: 'SnailblastUploadAddressesList',

  data: () => ({
    searchIcon: mdiMagnify,
    deleteIcon: mdiDelete,
    editIcon: mdiPencil,
    search: '',
    deleting: false
  }),

  computed: {
    items() {
      let list = this.addresses.addresses;
      if (this.search) {
        const search = this.search.toLowerCase();
        list = list.filter(
          (x) =>
            this.$options.filters.lower(x.fullName).includes(search) ||
            this.$options.filters.lower(x.companyName).includes(search) ||
            this.$options.filters.lower(x.line1).includes(search) ||
            this.$options.filters.lower(x.line2).includes(search) ||
            this.$options.filters.lower(x.city).includes(search) ||
            this.$options.filters.lower(x.state).includes(search) ||
            this.$options.filters.lower(x.zip).includes(search)
        );
      }
      return orderBy(list, ['hasBasicError'], ['desc']);
    },
    icon() {
      return (address) => {
        if (address.hasBasicError) {
          return { icon: mdiAlert, color: 'warning', label: 'ERROR' };
        }
        return { icon: mdiCheckboxMarked, color: 'green', label: 'APPROVED' };
      };
    },
    ...mapGetters({
      addresses: 'snailblast/campaign/addresses',
      campaign: 'snailblast/campaign/edit_campaign',
      state_abbr: 'lookups/state_abbr'
    })
  },

  methods: {
    editAddress(id) {
      this.$store.commit('drawers/SET_DATA', {
        drawerId: DRAWERS.SNAILBLAST_UPLOAD_ADDRESS_EDIT,
        data: { addressId: id }
      });
      this.$store.commit('drawers/OPEN_DRAWER', DRAWERS.SNAILBLAST_UPLOAD_ADDRESS_EDIT);
    },
    async deleteAddress(id) {
      if (
        await this.$root.$confirm('Delete Address', 'Are you sure you want to delete this address?')
      ) {
        this.deleting = true;
        this.$store
          .dispatch('snailblast/campaign/deleteAddress', id)
          .catch((error) => {
            this.logError(error, 'Error deleting address');
          })
          .finally(() => {
            this.deleting = false;
          });
      }
    }
  }
};
</script>
