<template>
  <Drawer
    :id="drawerId"
    :loading="loading"
    title="Update Card"
    padded
    @close="show = false"
  >
    <div v-if="card">
      <v-alert
        border="left"
        text
        colored-border
        color="primary"
        class="mb-6 font-weight-bold"
      >
        Updating {{ card.displaySimple }}
      </v-alert>
      <v-form ref="form">
        <v-text-field
          v-model="card.name"
          outlined
          :rules="[$rules.required]"
          persistent-placeholder
          label="Name on Card"
          placeholder="Name on Card"
        />

        <v-text-field
          v-model="card.address.zip"
          outlined
          persistent-placeholder
          label="Postal / Zip Code"
          placeholder="Postal / Zip Code"
          :rules="[$rules.required]"
        />

        <v-select
          v-model="card.expMonth"
          :items="months"
          item-text="value"
          item-value="key"
          outlined
          persistent-placeholder
          label="Expiration Month"
          placeholder="Expiration Month"
          :rules="[$rules.required]"
        />

        <v-select
          v-model="card.expYear"
          :items="years"
          item-text="value"
          item-value="key"
          outlined
          persistent-placeholder
          label="Expiration Year"
          placeholder="Expiration Year"
          hide-details
          :rules="[$rules.required]"
        />

        <v-checkbox
          v-if="!card.isDefault"
          v-model="card.isDefault"
          label="Make this my default card?"
          hide-details
        />

        <v-btn
          color="primary"
          block
          large
          :loading="saving"
          class="mt-5"
          @click="save"
        >
          Save
        </v-btn>

        <v-divider class="my-5" />

        <v-btn
          color="error"
          outlined
          block
          large
          :loading="deleting"
          @click="deleteCard"
        >
          Delete
        </v-btn>
      </v-form>
    </div>
  </Drawer>
</template>

<script>
import { DRAWERS } from '../../utils/constants';
import Drawer from '../../components/layout/Drawer';

export default {
  name: 'UpdateCardDrawer',

  components: {
    Drawer
  },

  data() {
    return {
      show: false,
      loading: false,
      saving: false,
      deleting: false,
      drawerId: DRAWERS.UPDATE_CARD_DRAWER
    };
  },

  computed: {
    card() {
      return this.$store.getters['cards/selectedCard'];
    },

    months() {
      return this.$store.state.lookups.months;
    },

    years() {
      return this.$store.state.lookups.years;
    }
  },

  methods: {
    save() {
      var valid = this.$refs.form.validate();
      if (!valid) return;
      var payload = {
        id: this.card.id,
        name: this.card.name,
        zip: this.card.address.zip,
        expMonth: this.card.expMonth,
        expYear: this.card.expYear,
        isDefault: this.card.isDefault
      };
      this.saving = true;
      this.$store
        .dispatch('cards/update', payload)
        .then(async () => {
          await this.$store.dispatch('cards/getAll');
          this.$store.commit('drawers/CLOSE_DRAWER', this.drawerId);
        })
        .finally(() => {
          this.saving = false;
        });
    },

    deleteCard() {
      this.deleting = true;
      this.$store
        .dispatch('cards/delete', this.card.id)
        .then(async (result) => {
          if (!result.success) {
            this.$root.$alert('Error deleting card', result.message);
            return;
          }
          await this.$store.dispatch('cards/getAll');
          this.$store.commit('drawers/CLOSE_DRAWER', this.drawerId);
        })
        .finally(() => {
          this.deleting = false;
        });
    }
  }
};
</script>

<style></style>
