<template>
  <div class="d-flex align-center rounded bordered border-light overflow-hidden body-2">
    <div
      class="d-flex align-center justify-center pa-3"
      style="width: 150px"
    >
      <v-img
        :src="imageUrl"
        max-width="135px"
        max-height="110px"
        class="rounded"
        contain
      />
    </div>
    <div class="overflow-hidden mx-3">
      <div class="font-weight-bold body-1 text-truncate">
        {{ name }}
      </div>
      <div class="caption text-truncate">
        {{ text1 }}
      </div>
      <div class="caption text-truncate">{{ modifiedTerm }} {{ text2 }}</div>
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectProjectCard',

  props: {
    name: {
      type: String,
      default: ''
    },
    isUpload: Boolean,
    text1: {
      type: String,
      default: ''
    },
    text2: {
      type: String,
      default: ''
    },
    imageUrl: {
      type: String,
      default: ''
    }
  },

  data() {
    return {};
  },

  computed: {
    modifiedTerm() {
      return this.isUpload ? 'Uploaded: ' : 'Modified: ';
    }
  }
};
</script>
